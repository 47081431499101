import { InputType, ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';
import Program from './Program';
import Coach, { CoachProperties } from './Coach';
import Sport from './Sport';
import Goal from './Goal';
import Level from './Level';
import SubFormChallengeSession from 'components/form/subform/SubFormChallengeSession';
import { ChallengeSessionPlaceHolder, ChallengeSessionProperties } from './ChallengeSession';
// import { ChallengeSessionProperties } from './ChallengeSession';
const ChallengeProperties = {
    id: 'id',
    title: 'title',
    url: 'url',
    coach: 'coach',
    sport: 'sport',
    goals: 'goals',
    levels: 'levels',
    photo: 'photo',
    photo2: 'photo2',
    description: 'description',
    challengeSessions: 'challengeSessions',
};

const Challenge: ResourceType = {
    resourcePath: 'challenges',
    placeHolder: {
        [ChallengeProperties.id]: '',
        [ChallengeProperties.title]: '',
        [ChallengeProperties.coach]: '',
        [ChallengeProperties.sport]: '',
        [ChallengeProperties.goals]: [],
        [ChallengeProperties.challengeSessions]: [ChallengeSessionPlaceHolder],
        [ChallengeProperties.levels]: [],
        [ChallengeProperties.photo]: null,
        [ChallengeProperties.photo2]: null,
        [ChallengeProperties.description]: '',
    },
    validationSchema: yup.object({
        title: yup.string().required('Ce champ est obligatoire'),
        coach: yup.string().ensure().required('Ce champ est obligatoire'),
    }),
    configForm: {
        label: 'Challenge',
        listInputs: [
            {
                label: 'Nom',
                property: ChallengeProperties.title,
            },
            {
                label: Coach.configForm.label,
                property: ChallengeProperties.coach,
                type: InputType.entity,
                options: {
                    resource: Coach.resourcePath,
                    label: CoachProperties.signature,
                },
            },
            {
                label: Sport.configForm.label,
                property: ChallengeProperties.sport,
                type: InputType.entity,
                options: {
                    resource: Sport.resourcePath,
                },
            },
            {
                label: Goal.configForm.label,
                property: ChallengeProperties.goals,
                type: InputType.entity,
                options: {
                    resource: Goal.resourcePath,
                    multiple: true,
                },
            },
            {
                label: Level.configForm.label,
                property: ChallengeProperties.levels,
                type: InputType.entity,
                options: {
                    resource: Level.resourcePath,
                    multiple: true,
                },
            },
            {
                label: 'Description',
                property: ChallengeProperties.description,
            },
            {
                label: 'Photo',
                property: ChallengeProperties.photo,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Photo 2',
                property: ChallengeProperties.photo2,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Sessions',
                property: ChallengeProperties.challengeSessions,
                type: InputType.subform,
                options: {
                    subform: SubFormChallengeSession,
                    multiple: true,
                    sortable: {
                        weight: ChallengeSessionProperties.sessionNumber,
                    },
                },
            },
        ],
    },
    configList: {
        label: 'Challenges',
        listProperties: [...Program.configList.listProperties],
        allowAdd: true,
        filters: [
            {
                label: 'Titre',
                property: ChallengeProperties.title,
            },
        ],
        defaultOrder: {
            property: 'id',
            order: 'desc',
        },
    },
    actions: {
        delete: true,
    },
};

export default Challenge;
export { ChallengeProperties };

import { Chip } from '@mui/material';

class TagConverter {
    static display = (element: any, label?: string): JSX.Element => {
        const finalLabel = label ? element[label] : element;
        return (
            <Chip
                key={finalLabel}
                size="small"
                label={finalLabel}
                sx={{
                    marginRight: '5px',
                    marginBottom: '5px',
                    fontWeight: 'bold',
                    fontSize: '0.75rem',
                }}
            />
        );
    };
}

export default TagConverter;

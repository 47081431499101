const ChallengeSessionProperties = {
    id: 'id',
    sessionNumber: 'sessionNumber',
    week: 'week',
    challengeSessionVideos: 'challengeSessionVideos',
};

const ChallengeSessionVideosPlaceHolder = {
    id: '',
    position: 0,
    video: null,
};

const ChallengeSessionPlaceHolder = {
    [ChallengeSessionProperties.id]: '',
    [ChallengeSessionProperties.sessionNumber]: 0,
    [ChallengeSessionProperties.week]: 0,
    [ChallengeSessionProperties.challengeSessionVideos]: [ChallengeSessionVideosPlaceHolder],
};

export { ChallengeSessionProperties, ChallengeSessionPlaceHolder, ChallengeSessionVideosPlaceHolder };

import { InputType, ResourceType, ImageConverter } from 'm6BoBuilder';
import * as yup from 'yup';

const BodyPartProperties = {
    id: 'id',
    title: 'title',
    icon: 'icon',
    photo: 'photo',
};

const BodyPart: ResourceType = {
    resourcePath: 'body_parts',
    placeHolder: {
        [BodyPartProperties.id]: 0,
        [BodyPartProperties.title]: '',
        [BodyPartProperties.icon]: null,
        [BodyPartProperties.photo]: null,
    },

    validationSchema: yup.object({
        [BodyPartProperties.title]: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Partie du corps',
        listInputs: [
            {
                label: 'Nom',
                property: BodyPartProperties.title,
            },
            {
                label: 'Icône',
                property: BodyPartProperties.icon,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Photo',
                property: BodyPartProperties.photo,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
        ],
    },

    configList: {
        label: 'Parties du corps',
        listProperties: [
            {
                label: '#',
                property: BodyPartProperties.id,
                sortable: true,
                styles: {
                    width: '7%',
                },
            },
            {
                label: 'Titre',
                property: BodyPartProperties.title,
                sortable: true,
            },
            {
                label: 'Icône',
                property: BodyPartProperties.photo,
                transform: ImageConverter.display,
            },
            {
                label: 'Photo',
                property: BodyPartProperties.photo,
                transform: ImageConverter.display,
            },
        ],
        allowAdd: true,
    },
    actions: {
        delete: true,
    },
};

export default BodyPart;
export { BodyPartProperties };

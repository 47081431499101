/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState, useRef } from 'react';
import { Grid, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';

import { InputType } from '../types/forms/ConfigFormType';
import { ListFilterType } from '../types/lists/ListFilterType';
import { FilterType } from '../types/FilterType';

import FormEntity from './form/FormEntity';
import FormDate from './form/FormDate';
import FormSelect from './form/FormSelect';

type TableListFiltersProps = {
    filters: FilterType[];
    defaultValues: filterValuesType;
    updateFilters: (filters: ListFilterType) => void;
    values: filterValuesType;
    disablePagination?: boolean;
};

type filterValuesType = {
    [key: string]: any;
};

const TableListFilters = ({
    filters,
    defaultValues,
    updateFilters,
    values,
    disablePagination = false,
}: TableListFiltersProps): JSX.Element => {
    if (filters.length === 0) {
        return <></>;
    }

    let changeTimer: ReturnType<typeof setTimeout>;
    const [filterValues, setFilterValues] = useState(values);
    const textFieldsRef = useRef([]);

    useEffect(() => {
        if (values !== filterValues) {
            updateFilters(filterValues);
        }
    }, [filterValues]);

    const handleTextChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (ev.target.value.length > 2) {
            clearTimeout(changeTimer);
            changeTimer = setTimeout(() => {
                setFilterValues({ ...filterValues, [ev.target.name]: ev.target.value });
            }, 700);
        }
    };

    const handleSelectChange = (ev: SelectChangeEvent<string>) => {
        setFilterValues({ ...filterValues, [ev.target.name]: ev.target.value });
    };

    const handleEntityUpdate = (entity: Record<string, any>): void => {
        setFilterValues({ ...filterValues, ...entity });
        updateFilters({ ...filterValues, ...entity });
    };

    const handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
        // Prevent form submit
        ev.preventDefault();
        //const formData = new FormData(ev.target);
    };

    const handleResetForm = () => {
        textFieldsRef.current.map((field: any) => {
            field.value = '';
            field.focus();
        });
        setFilterValues({ ...defaultValues });
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container columnSpacing={2} padding={2}>
                {filters?.map((filter: FilterType, i: number) => {
                    if ([InputType.text, InputType.integer, InputType.float].includes(filter.type ?? InputType.text)) {
                        return (
                            <Grid item key={filter.property} padding={0}>
                                <TextField
                                    // @ts-ignore
                                    inputRef={(el) => (textFieldsRef.current[i] = el)}
                                    size="small"
                                    key={filter.property}
                                    id={filter.property}
                                    type={InputType.text}
                                    fullWidth
                                    label={filter.label}
                                    name={filter.property}
                                    onChange={handleTextChange}
                                />
                            </Grid>
                        );
                    }
                    if (filter.type === InputType.entity) {
                        return (
                            <Grid item key={filter.property} padding={0}>
                                <FormEntity
                                    key={filter.property}
                                    entity={filterValues}
                                    updateEntity={handleEntityUpdate}
                                    configLine={filter}
                                    fieldSize={'small'}
                                    fieldWidth={300}
                                />
                            </Grid>
                        );
                    }
                    if (filter.type === InputType.boolean) {
                        return (
                            <Grid item key={filter.property} padding={0}>
                                <FormControl size={'small'} sx={{ minWidth: 120 }}>
                                    <InputLabel>{filter.label}</InputLabel>
                                    <Select
                                        // labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        autoWidth
                                        value={''}
                                        name={filter.property}
                                        label={filter.label}
                                        onChange={handleSelectChange}
                                    >
                                        <MenuItem value="">Tout</MenuItem>
                                        <MenuItem value={1}>Oui</MenuItem>
                                        <MenuItem value={0}>Non</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        );
                    }
                    if (filter.type === InputType.date) {
                        return (
                            <Grid item key={filter.property}>
                                <FormDate
                                    key={filter.property}
                                    entity={filterValues}
                                    updateEntity={handleEntityUpdate}
                                    configLine={filter}
                                    fieldSize={'small'}
                                    fieldWidth={300}
                                />
                            </Grid>
                        );
                    }
                    if (filter.type === InputType.select) {
                        return (
                            <Grid item key={filter.property}>
                                <FormSelect
                                    key={filter.property}
                                    entity={filterValues}
                                    updateEntity={handleEntityUpdate}
                                    configLine={filter}
                                    fieldSize={'small'}
                                    fieldWidth={300}
                                />
                            </Grid>
                        );
                    }
                })}
                {!disablePagination && (
                    <Grid item pt={0}>
                        <FormControl size={'small'} sx={{ minWidth: 60 }}>
                            <InputLabel>Items par page</InputLabel>
                            <Select
                                native
                                value={filterValues.itemsPerPage}
                                name={'itemsPerPage'}
                                onChange={handleSelectChange}
                            >
                                {[5, 10, 20, 50].map(
                                    (element: number): JSX.Element => (
                                        <option key={`page_${element}`} value={element}>
                                            {element}
                                        </option>
                                    ),
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                {
                    <Grid item pt={0}>
                        <Button color="primary" variant="contained" fullWidth type="submit" onClick={handleResetForm}>
                            Reset
                        </Button>
                    </Grid>
                }
            </Grid>
        </form>
    );
};
export default TableListFilters;

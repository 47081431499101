import { ApiLoading, ApiContext, flashMessage, flashType, DateConverter, WhiteBox } from 'm6BoBuilder';

import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Video from 'twilio-video';
import { VideoRoomMonitor } from '@twilio/video-room-monitor';
import { Room } from 'twilio-video';
import { Player } from '@twilio/live-player-sdk';
import { Typography, Grid, Button, Chip, Stack, Fab, Tooltip, IconButton, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Quiz, VideocamOff, Videocam } from '@mui/icons-material';
import { IconCast, IconCastOff } from '@tabler/icons';
import { AxiosError } from 'axios';
import LiveSession from 'config/resources/LiveSession';

const StreamContainer = styled('div')(({ theme }) => ({
    position: 'relative',
    marginTop: 25,
    paddingTop: '56.3%',
    backgroundColor: theme.palette.grey[200],
    '& > video': {
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        display: 'block',
        position: 'absolute',
    },
}));

const CenterContainer = styled('div')(({}) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}));

const TopContainer = styled('div')(() => ({
    position: 'absolute',
    top: 25,
    right: 25,
    zIndex: 1000,
}));

const ActionsContainer = styled(Stack)(() => ({
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    marginTop: '25px',
    position: 'relative',
    '& > button:not(:last-child)': {
        marginRight: 20,
        color: '#fff',
    },
}));

const LiveSessionStreamScreen = (): JSX.Element => {
    const { id } = useParams<string>();
    const apiService = useContext(ApiContext);
    const isApiLoading = useContext(ApiLoading);
    const [loading, setLoading] = useState(true);
    const [isStreaming, setIsStreaming] = useState(false);
    const [liveSession, setLiveSession] = useState<any>(null);
    const [isVideoOn, setVideoOn] = useState(true);
    const [isClientOpen, setClientOpen] = useState(false);
    const [videoTrack, setVideoTrack] = useState<Video.LocalVideoTrack>();
    const [room, setRoom] = useState<Room | null>(null);

    const streamerRef = useRef<HTMLDivElement>(null);
    const playerRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (id !== undefined && !isApiLoading) {
            apiService
                .get(LiveSession.resourcePath, id)
                .then((data) => {
                    if (data !== null) {
                        setLoading(true);
                        setLiveSession(data.data);
                        handlePreviewRender();
                    }
                })
                .catch((error: AxiosError) => {
                    flashMessage(error.response?.data['hydra:description'] ?? error.message, flashType.ERROR);
                });
        } else {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (liveSession) {
            setLoading(false);
        }
    }, [liveSession]);

    useEffect(() => {
        if (room) {
            // room.on('participantConnected', participantConnected);
            // room.on('participantDisconnected', participantDisconnected);
            room.on('disconnected', () => {
                closeLocalVideoTrack();
            });
            //room.participants.forEach(participantConnected);
            VideoRoomMonitor.registerVideoRoom(room);
            setIsStreaming(true);
        }
    }, [room]);

    useEffect(() => {
        console.log(isVideoOn);
        showHideVideo();
    }, [isVideoOn]);

    const handlePreviewRender = async () => {
        const videoTrack = await Video.createLocalVideoTrack({
            name: 'video',
            width: 1280,
            height: 720,
        });
        setVideoTrack(videoTrack);
        const trackElement = videoTrack.attach();
        if (null !== streamerRef.current) {
            streamerRef.current.appendChild(trackElement);
            setVideoOn(true);
        }
    };

    const showHideVideo = () => {
        if (!isVideoOn) {
            removePreviewVideo();
        } else {
            if (videoTrack && streamerRef.current !== null) {
                const trackElement = videoTrack.attach();
                streamerRef.current.appendChild(trackElement);
                videoTrack.restart();
            }
        }
    };

    const removePreviewVideo = () => {
        if (streamerRef.current !== null) {
            const video = streamerRef.current?.querySelector('video');
            if (video !== null) {
                streamerRef.current.removeChild(video);
            }
            if (videoTrack) {
                videoTrack.stop();
            }
        }
    };

    const handleStartStream = async () => {
        const data: any = await apiService.create(`${LiveSession.resourcePath}/${liveSession.id}/start`, {});
        if (data !== null && videoTrack) {
            const audioTrack = await Video.createLocalAudioTrack({
                name: 'audio',
            });
            if (null !== streamerRef.current) {
                Video.connect(data.data.streamerToken, {
                    name: data.data.roomId,
                    audio: true,
                    video: true,
                    tracks: [videoTrack, audioTrack],
                }).then((room) => {
                    setRoom(room);
                    setVideoOn(true);
                });
            }
        }
    };

    const handleStopStream = async () => {
        await apiService.create(`${LiveSession.resourcePath}/${liveSession.id}/stop`, {});
        closeLocalVideoTrack();
    };

    const handleStartPlayer = async () => {
        if (null !== playerRef.current) {
            const data: any = await apiService.create(`${LiveSession.resourcePath}/${liveSession.id}/token`, {});
            const player = await Player.connect(data.data.token, {
                playerWasmAssetsPath: '/player',
            });

            player.on(Player.Event.StateChanged, (state: Player.State) => {
                console.log(state);
            });
            player.play();
            player.attach(playerRef.current);
            setClientOpen(true);
        }
    };

    const closeLocalVideoTrack = () => {
        setIsStreaming(false);
        // if (videoTrack) {
        //     removePreviewVideo();
        //     videoTrack.stop();
        // }
    };

    return (
        <>
            {!loading && liveSession && (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <WhiteBox sx={{ paddingY: 4, paddingX: 2 }}>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography component="h2" variant="h3" sx={{ mb: 2 }}>
                                        {liveSession.live.title}
                                    </Typography>
                                    <Typography component="span" variant="h4" sx={{ mb: 2 }}>
                                        {DateConverter.display(liveSession.sessionDate, true)}
                                    </Typography>
                                </Stack>
                                <StreamContainer ref={streamerRef}>
                                    <TopContainer>
                                        {isStreaming && (
                                            <IconButton sx={{ mr: 2 }} onClick={() => VideoRoomMonitor.openMonitor()}>
                                                <Quiz />
                                            </IconButton>
                                        )}
                                        <Chip
                                            sx={{ fontWeight: 'bold' }}
                                            icon={isStreaming ? <IconCast size={20} /> : <IconCastOff size={20} />}
                                            label={isStreaming ? 'Live en cours' : 'Live non diffusé'}
                                            color={isStreaming ? 'success' : 'error'}
                                        />
                                    </TopContainer>
                                    <CenterContainer>
                                        <VideocamOff fontSize="large" />
                                    </CenterContainer>
                                </StreamContainer>
                                <ActionsContainer>
                                    <Tooltip title={isVideoOn ? 'Désactiver la caméra' : 'Activer la caméra'}>
                                        <Fab
                                            color={isVideoOn ? 'primary' : 'error'}
                                            size="medium"
                                            onClick={() => setVideoOn(!isVideoOn)}
                                        >
                                            {isVideoOn ? (
                                                <Videocam fontSize="small" />
                                            ) : (
                                                <VideocamOff fontSize="small" />
                                            )}
                                        </Fab>
                                    </Tooltip>
                                    {!isStreaming ? (
                                        <Fab color="secondary" variant="extended" onClick={() => handleStartStream()}>
                                            <IconCast style={{ marginRight: 10 }} />
                                            Lancer le live
                                        </Fab>
                                    ) : (
                                        <>
                                            <Fab color="error" variant="extended" onClick={() => handleStopStream()}>
                                                <IconCastOff style={{ marginRight: 10 }} />
                                                Stopper le live
                                            </Fab>
                                            <Button
                                                sx={{ position: 'absolute', right: 0 }}
                                                variant="text"
                                                onClick={() => handleStartPlayer()}
                                            >
                                                Que voient mes clients ?
                                            </Button>
                                        </>
                                    )}
                                </ActionsContainer>
                            </WhiteBox>
                        </Grid>
                    </Grid>

                    <Modal open={isClientOpen} keepMounted onClose={() => setClientOpen(false)}>
                        <WhiteBox
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '90%',
                                maxWidth: 800,
                                paddingY: 4,
                                paddingX: 2,
                            }}
                        >
                            <Typography component="h3" variant="h4" sx={{ mb: 2, textAlign: 'center' }}>
                                Vue client :
                            </Typography>
                            <video ref={playerRef} style={{ width: '100%' }}></video>
                        </WhiteBox>
                    </Modal>
                </>
            )}
        </>
    );
};

export default LiveSessionStreamScreen;

import { ChangeEvent, useContext, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';
import { AxiosError } from 'axios';
import { ApiContext } from '../../AppBoBuilder';
import { flashMessage, flashType } from '../Flash';
import { FormElementPropsType } from '../../types/forms/FormElementPropsType';
import EntityType from '../../types/EntityType';
import ApiService from '../../services/ApiService';

type ValueType = { value: unknown; label: string };

type FormEntityPropsType = FormElementPropsType & { keepEntity?: boolean };

const FormEntity = ({
    entity,
    updateEntity,
    configLine,
    error,
    fieldSize = 'medium',
    fieldWidth = 'auto',
    keepEntity,
}: FormEntityPropsType): JSX.Element => {
    // receive path as string like 'window.width' and return value of obj[window][width]
    const resolve = (path: string, obj: Record<string, any>) => {
        return path.split('.').reduce(function (prev: any, curr: any) {
            return prev ? prev[curr] : null;
        }, obj || self);
    };

    const convertFormat = (list: EntityType[]) => {
        return list.map((element: Record<string, any>): ValueType => {
            let label = element[configLine.options?.label ?? 'title'];
            if (configLine.options?.details) {
                const itemDetails =
                    configLine.options?.details.prelabel + resolve(configLine.options.details.path, element);
                label = label + ' (' + itemDetails + ')';
            }

            return {
                value: element['@id'],
                label: label,
            };
        });
    };

    let defaultValue: ValueType | ValueType[] = { value: '', label: '' };
    if (entity[configLine.property] != null) {
        const values = entity[configLine.property];
        defaultValue = Array.isArray(values)
            ? convertFormat(values)
            : {
                  value: keepEntity ? values : values['@id'] ?? '',
                  label: values[configLine.options?.label ?? 'title'] ?? '',
              };
    }

    const [selectedValue, setSelectedValue] = useState<ValueType | ValueType[] | null>(defaultValue);
    const apiService: ApiService = useContext(ApiContext);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState<ValueType[] | false>([]);

    const searchOptions = (search = '') => {
        if (!configLine.options?.resource) {
            throw new Error(`Missing resource type in "${configLine.property}" field options`);
        }
        const options = search == '' ? {} : { [configLine.options?.label ?? 'title']: search };
        apiService
            .getAll(configLine.options.resource, { page: 1 }, options)
            .then((data) => {
                if (data !== null) {
                    let options = convertFormat(data.data['hydra:member']);
                    if (Array.isArray(defaultValue)) {
                        options = [...options, ...defaultValue];
                    } else {
                        options.push(defaultValue);
                    }
                    setOptions(options);
                    setLoading(false);
                }
            })
            .catch((error: AxiosError) => {
                setOptions(false);
                flashMessage(error.response?.data['hydra:description'] ?? error.message, flashType.ERROR);
            });
    };

    useEffect(() => {
        if (!configLine.options?.resource) {
            throw new Error(`Missing resource type in "${configLine.property}" field options`);
        }
        if (entity[configLine.property] != null && !keepEntity) {
            // Reformat subresource (need to be just IRIs for API update)
            let iri: string | string[] = '';
            if (Array.isArray(entity[configLine.property])) {
                iri = entity[configLine.property].map((element: EntityType) => {
                    return element['@id'];
                });
            } else {
                iri = entity[configLine.property]['@id'];
            }
            entity[configLine.property] = iri;
        }
    }, []);

    const handleChange = (_e: ChangeEvent<unknown>, value: ValueType | ValueType[] | null) => {
        searchOptions(Array.isArray(value) ? '' : value?.label);
        const values = Array.isArray(value) ? value.map((element: ValueType) => element.value) : value?.value;
        const entity2 = { ...entity, [configLine.property]: value === null ? value : values };
        updateEntity(entity2, configLine.property);
        setSelectedValue(value);
    };

    if (!options) {
        return <>API UNAVAILABLE</>;
    }

    return (
        <Autocomplete
            size={fieldSize}
            multiple={!!configLine.options?.multiple}
            id={configLine.label}
            style={{ width: fieldWidth }}
            value={selectedValue}
            open={open}
            onOpen={() => {
                if (options && options.length === 0) {
                    searchOptions();
                }
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option: ValueType, value) => option.value === value.value}
            filterOptions={(x) => x}
            getOptionLabel={(option) => option.label}
            options={options}
            loading={loading}
            onChange={handleChange}
            onInputChange={(_event, newInputValue) => {
                if (open) {
                    searchOptions(newInputValue);
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={error}
                    label={configLine.label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ?? <CircularProgress color="inherit" size={20} />}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};
export default FormEntity;

import * as yup from 'yup';
import { DateConverter, ResourceType, InputType, ImageConverter, SelectRenderType } from 'm6BoBuilder';

const PromotionalMediaProperties = {
    id: 'id',
    bodyMedia: 'bodyMedia',
    publicationStartDate: 'publicationStartDate',
    publicationEndDate: 'publicationEndDate',
    published: 'published',
    url: 'url',
    target: 'target',
    position: 'position',
};

const PromotionalMedia: ResourceType = {
    resourcePath: 'bo-promotional-medias',
    placeHolder: {
        [PromotionalMediaProperties.id]: 0,
        [PromotionalMediaProperties.bodyMedia]: null,
        [PromotionalMediaProperties.publicationStartDate]: new Date(),
        [PromotionalMediaProperties.publicationEndDate]: undefined,
        [PromotionalMediaProperties.published]: false,
        [PromotionalMediaProperties.target]: false,
        [PromotionalMediaProperties.position]: 1,
        [PromotionalMediaProperties.url]: '',
    },
    validationSchema: yup.object({
        [PromotionalMediaProperties.publicationStartDate]: yup.date().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Medias promotionnels',
        listInputs: [
            {
                label: 'Image',
                property: PromotionalMediaProperties.bodyMedia,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Publiée le',
                property: PromotionalMediaProperties.publicationStartDate,
                type: InputType.date,
            },
            {
                label: 'Fin le',
                property: PromotionalMediaProperties.publicationEndDate,
                type: InputType.date,
            },
            {
                label: 'Ouvrir dans un nouvel onglet',
                property: PromotionalMediaProperties.target,
                type: InputType.boolean,
            },
            {
                label: 'Url',
                property: PromotionalMediaProperties.url,
            },
            {
                label: 'Position',
                property: PromotionalMediaProperties.position,
                type: InputType.select,
                options: {
                    choices: [
                        { value: 1, label: '1' },
                        { value: 2, label: '2' },
                    ],
                    render: SelectRenderType.radio,
                },
            },
            {
                label: 'Publié',
                property: PromotionalMediaProperties.published,
                type: InputType.boolean,
            },
        ],
    },

    configList: {
        label: 'Bandeau promotionnel',
        listProperties: [
            {
                label: 'Image',
                property: PromotionalMediaProperties.bodyMedia,
                transform: ImageConverter.display,
            },
            {
                label: 'Publié le',
                property: PromotionalMediaProperties.publicationStartDate,
                transform: DateConverter.display,
                sortable: true,
            },
            {
                label: 'Fin le',
                property: PromotionalMediaProperties.publicationEndDate,
                transform: DateConverter.display,
                sortable: true,
            },
            {
                label: 'Url',
                property: PromotionalMediaProperties.url,
                sortable: true,
            },
            {
                label: 'Position',
                property: PromotionalMediaProperties.position,
            },
            {
                label: 'Publié',
                property: PromotionalMediaProperties.published,
            },
        ],
        allowAdd: true,
    },
    actions: {
        delete: true,
    },
};

export default PromotionalMedia;
export { PromotionalMediaProperties };

import { InputType, ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';

const ArticleAuthorProperties = {
    avatar: 'avatar',
    firstName: 'firstName',
    lastName: 'lastName',
    job: 'job',
    displayName: 'displayName',
};

const ArticleAuthor: ResourceType = {
    resourcePath: 'bo_article_author',
    placeHolder: {
        [ArticleAuthorProperties.avatar]: null,
        [ArticleAuthorProperties.firstName]: '',
        [ArticleAuthorProperties.lastName]: '',
        [ArticleAuthorProperties.job]: '',
        [ArticleAuthorProperties.displayName]: '',
    },
    validationSchema: yup.object({
        [ArticleAuthorProperties.firstName]: yup.string().required('Ce champ est obligatoire'),
        [ArticleAuthorProperties.lastName]: yup.string().required('Ce champ est obligatoire'),
        [ArticleAuthorProperties.job]: yup.string().required('Ce champ est obligatoire'),
    }),
    configForm: {
        label: "Auteurs d'articles",
        listInputs: [
            {
                label: 'Nom',
                property: ArticleAuthorProperties.lastName,
            },
            {
                label: 'Prénom',
                property: ArticleAuthorProperties.firstName,
            },
            {
                label: 'Emploi',
                property: ArticleAuthorProperties.job,
            },
            {
                label: 'Avatar',
                property: ArticleAuthorProperties.avatar,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
        ],
    },
    configList: {
        label: "Auteurs d'articles",
        listProperties: [
            {
                label: 'Prénom',
                property: ArticleAuthorProperties.firstName,
                sortable: true,
            },
            {
                label: 'Nom',
                property: ArticleAuthorProperties.lastName,
                sortable: true,
            },
            {
                label: 'Emploi',
                property: ArticleAuthorProperties.job,
                sortable: true,
            },
        ],
        allowAdd: true,
    },
    actions: {
        delete: true,
    },
};

export default ArticleAuthor;
export { ArticleAuthorProperties };

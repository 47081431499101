import React, { Dispatch, SetStateAction, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import LandingScreen from '../../screens/LandingScreen';
import DefaultListScreen from '../../screens/DefaultListScreen';
import DefaultEditScreen from '../../screens/DefaultEditScreen';
import Login from '../../screens/Login';
import Layout from '../../components/layout';
import NotFoundScreen from '../../screens/NotFoundScreen';
import { ScreenType } from '../../types/ScreenType';
import { ConfigContext, UserContext } from '../../AppBoBuilder';
import User from '../../services/User';
import { ConfigType } from '../../types/ConfigType';

type ScreenSwitcherPropsType = {
    loggedIn: boolean;
    setLoggedIn: Dispatch<SetStateAction<boolean>>;
    handleLogout: () => void;
};

const ScreenSwitcher = React.memo(({ loggedIn, setLoggedIn, handleLogout }: ScreenSwitcherPropsType): JSX.Element => {
    const config: ConfigType | null = useContext(ConfigContext);
    if (!config) {
        throw new Error('Missing initialized config.');
    }
    const user: User = useContext(UserContext);
    const routeList: JSX.Element[] = [];

    config.screens.forEach((screen) => {
        const ListScreen: ScreenType = screen.listScreen ?? DefaultListScreen;
        const CreateScreen: ScreenType = screen.createScreen ?? DefaultEditScreen;
        const DetailScreen: ScreenType = screen.detailScreen ?? DefaultEditScreen;

        if (user.hasRole(screen.accessControl.create)) {
            //create
            routeList.push(
                <Route
                    key={`create_${screen.resource.resourcePath}`}
                    path={`/${screen.resource.resourcePath}/new`}
                    element={<CreateScreen resource={screen.resource} />}
                />,
            );
        }
        if (user.hasRole(screen.accessControl.edit)) {
            //edit
            routeList.push(
                <Route
                    key={`edit_${screen.resource.resourcePath}`}
                    path={`/${screen.resource.resourcePath}/:id`}
                    element={<DetailScreen resource={screen.resource} />}
                />,
            );
        }
        if (user.hasRole(screen.accessControl.list)) {
            //list
            routeList.push(
                <Route
                    key={`list_${screen.resource.resourcePath}`}
                    path={`/${screen.resource.resourcePath}`}
                    element={<ListScreen resource={screen.resource} />}
                />,
            );
        }
    });

    return (
        <Routes>
            {!loggedIn ? (
                <Route path="/login" element={<Login setLoggedIn={setLoggedIn} />} />
            ) : (
                <Route path="/" element={<Layout handleLogout={handleLogout} />}>
                    <Route path="/" element={<LandingScreen />} />
                    {routeList.map((route) => route)}
                    {config.getCustomRouteList(user).map((route) => route)}
                    <Route path="*" element={<NotFoundScreen />} />
                </Route>
            )}
        </Routes>
    );
});
ScreenSwitcher.displayName = 'ScreenSwitcher';

export default ScreenSwitcher;

import { InputType, ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';

const LevelProperties = {
    id: 'id',
    title: 'title',
    description: 'description',
    subscriptionPhoto: 'subscriptionPhoto',
    minimalRecommendedTime: 'minimalRecommendedTime',
    recommendedKcals: 'recommendedKcals',
};

const Level: ResourceType = {
    resourcePath: 'levels',
    placeHolder: {
        [LevelProperties.id]: 0,
        [LevelProperties.title]: '',
        [LevelProperties.description]: '',
        [LevelProperties.subscriptionPhoto]: null,
        [LevelProperties.minimalRecommendedTime]: 0,
        [LevelProperties.recommendedKcals]: 0,
    },

    validationSchema: yup.object({
        [LevelProperties.title]: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Niveau',
        listInputs: [
            {
                label: 'Nom',
                property: LevelProperties.title,
            },
            {
                label: 'Temps min recommandé',
                property: LevelProperties.minimalRecommendedTime,
                type: InputType.integer,
            },
            {
                label: 'kcals recommandées',
                property: LevelProperties.recommendedKcals,
                type: InputType.integer,
            },
        ],
    },

    configList: {
        label: 'Niveaux',
        listProperties: [
            {
                label: '#',
                property: LevelProperties.id,
                sortable: true,
                styles: {
                    width: '7%',
                },
            },
            {
                label: 'Titre',
                property: LevelProperties.title,
                sortable: true,
            },
            {
                label: 'Temps min recommandé',
                property: LevelProperties.minimalRecommendedTime,
                sortable: true,
            },
            {
                label: 'kcals recommandées',
                property: LevelProperties.recommendedKcals,
                sortable: true,
            },
        ],
        allowAdd: false,
    },
    actions: {
        delete: false,
    },
};

export default Level;
export { LevelProperties };

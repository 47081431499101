import { InputType, ResourceType, TagConverter } from 'm6BoBuilder';
import * as yup from 'yup';
import Level from './Level';
import Coach, { CoachProperties } from './Coach';
import Goal from './Goal';
import Video from './Video';
import SubformProgramVideo from 'components/form/subform/SubformProgramVideo';
import { ProgramVideoProperties } from './ProgramVideo';
import Sport, { SportProperties } from './Sport';

const ProgramProperties = {
    id: 'id',
    title: 'title',
    url: 'url',
    coach: 'coach',
    sport: 'sport',
    goals: 'goals',
    levels: 'levels',
    programVideos: 'programVideos',
    photo: 'photo',
    photo2: 'photo2',
    creationDate: 'creationDate',
};

const Program: ResourceType = {
    resourcePath: 'programs',
    placeHolder: {
        [ProgramProperties.id]: '',
        [ProgramProperties.title]: '',
        [ProgramProperties.coach]: '',
        [ProgramProperties.sport]: '',
        [ProgramProperties.goals]: [],
        [ProgramProperties.levels]: [],
        [ProgramProperties.programVideos]: [],
        [ProgramProperties.photo]: null,
        [ProgramProperties.photo2]: null,
        [ProgramProperties.creationDate]: null,
    },
    validationSchema: yup.object({
        title: yup.string().required('Ce champ est obligatoire'),
        coach: yup.string().required('Ce champ est obligatoire'),
        sport: yup.string().required('Ce champ est obligatoire'),
    }),
    configForm: {
        label: 'Programme',
        listInputs: [
            {
                label: 'Nom',
                property: ProgramProperties.title,
                type: InputType.text,
            },
            {
                label: 'Date de création',
                property: ProgramProperties.creationDate,
                type: InputType.date,
            },
            {
                label: Coach.configForm.label,
                property: ProgramProperties.coach,
                type: InputType.entity,
                options: {
                    resource: Coach.resourcePath,
                    label: CoachProperties.signature,
                },
            },
            {
                label: Sport.configForm.label,
                property: ProgramProperties.sport,
                type: InputType.entity,
                options: {
                    resource: Sport.resourcePath,
                },
            },
            {
                label: Goal.configForm.label,
                property: ProgramProperties.goals,
                type: InputType.entity,
                options: {
                    resource: Goal.resourcePath,
                    multiple: true,
                },
            },
            {
                label: Level.configForm.label,
                property: ProgramProperties.levels,
                type: InputType.entity,
                options: {
                    resource: Level.resourcePath,
                    multiple: true,
                },
            },
            {
                label: 'Photo',
                property: ProgramProperties.photo,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Photo 2',
                property: ProgramProperties.photo2,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: Video.configList.label,
                property: ProgramProperties.programVideos,
                type: InputType.subform,
                options: {
                    subform: SubformProgramVideo,
                    multiple: true,
                    sortable: {
                        weight: ProgramVideoProperties.position,
                    },
                },
            },
        ],
    },
    configList: {
        label: 'Programmes',
        listProperties: [
            {
                label: '#',
                property: ProgramProperties.id,
                sortable: true,
                styles: {
                    width: '7%',
                },
            },
            {
                label: 'Nom',
                property: ProgramProperties.title,
                sortable: true,
            },
            {
                label: Coach.configForm.label,
                property: `${ProgramProperties.coach}.${CoachProperties.signature}`,
            },
            {
                label: Sport.configForm.label,
                property: `${ProgramProperties.coach}.${SportProperties.title}`,
            },
            {
                label: Level.configForm.label,
                property: ProgramProperties.levels,
                transform: (elements: []) => elements.map((elt) => TagConverter.display(elt, 'title')),
            },
            {
                label: Goal.configForm.label,
                property: ProgramProperties.goals,
                transform: (elements: []) => elements.map((elt) => TagConverter.display(elt, 'title')),
            },
        ],
        allowAdd: true,
        filters: [
            {
                label: 'Titre',
                property: ProgramProperties.title,
            },
        ],
        defaultOrder: {
            property: 'id',
            order: 'desc',
        },
    },
    actions: {
        delete: true,
    },
};

export default Program;
export { ProgramProperties };

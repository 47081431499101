import * as yup from 'yup';
import { InputType, TagConverter, DateConverter, ResourceType, ImageConverter } from 'm6BoBuilder';
import ArticleCategory, { ArticleCategoryProperties } from './ArticleCategory';
import ArticleAuthor, { ArticleAuthorProperties } from './ArticleAuthor';

const ArticleProperties = {
    title: 'title',
    author: 'author',
    publicationStartDate: 'publicationStartDate',
    published: 'published',
    category: 'category',
    body: 'body',
    teaser: 'teaser',
    video: 'video',
    titleMedia: 'titleMedia',
    bodyMedia: 'bodyMedia',
    modifiedDate: 'modifiedDate',
};

const Article: ResourceType = {
    resourcePath: 'bo_articles',
    placeHolder: {
        [ArticleProperties.title]: '',
        [ArticleProperties.author]: null,
        [ArticleProperties.category]: [],
        [ArticleProperties.teaser]: '',
        [ArticleProperties.body]: '',
        [ArticleProperties.publicationStartDate]: new Date(),
        [ArticleProperties.published]: false,
        [ArticleProperties.video]: '',
        [ArticleProperties.titleMedia]: null,
        [ArticleProperties.bodyMedia]: null,
        [ArticleProperties.modifiedDate]: null,
    },
    validationSchema: yup.object({
        title: yup.string().required('Ce champ est obligatoire'),
    }),
    configForm: {
        label: 'Article',
        listInputs: [
            {
                label: 'Nom',
                property: ArticleProperties.title,
            },
            {
                label: 'Auteur(e)',
                property: ArticleProperties.author,
                type: InputType.entity,
                options: {
                    resource: ArticleAuthor.resourcePath,
                    label: ArticleAuthorProperties.displayName,
                    multiple: false,
                },
            },
            {
                label: 'Catégories',
                property: ArticleProperties.category,
                type: InputType.entity,
                options: {
                    resource: ArticleCategory.resourcePath,
                    multiple: true,
                },
            },
            {
                label: 'Date de publication',
                property: ArticleProperties.publicationStartDate,
                type: InputType.date,
            },
            {
                label: 'Est publié ?',
                property: ArticleProperties.published,
                type: InputType.boolean,
            },
            {
                label: 'Teaser',
                property: ArticleProperties.teaser,
                options: {
                    textArea: true,
                },
            },
            {
                label: 'Contenu',
                property: ArticleProperties.body,
                options: {
                    textArea: true,
                },
            },
            {
                label: 'Image en-tête',
                property: ArticleProperties.titleMedia,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Image contenu',
                property: ArticleProperties.bodyMedia,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Url Youtube',
                property: ArticleProperties.video,
            },
            {
                label: 'Date de modification',
                property: ArticleProperties.modifiedDate,
                options: {
                    readOnly: true,
                },
            },
        ],
    },
    configList: {
        label: 'Articles',
        listProperties: [
            {
                label: 'Nom',
                property: ArticleProperties.title,
            },
            {
                label: 'Auteur(e)',
                property: `${ArticleProperties.author}.${ArticleAuthorProperties.displayName}`,
            },
            {
                label: 'Teaser',
                property: ArticleProperties.teaser,
            },
            {
                label: 'Statut',
                property: ArticleProperties.published,
            },
            {
                label: 'Image',
                property: ArticleProperties.titleMedia,
                transform: ImageConverter.display,
            },
            {
                label: 'Catégories',
                property: ArticleProperties.category,
                transform: (elements: []) => elements.map((elt) => TagConverter.display(elt, 'title')),
            },
            {
                label: 'Début publication',
                property: ArticleProperties.publicationStartDate,
                transform: DateConverter.display,
            },
            {
                label: 'Date de modification',
                property: ArticleProperties.modifiedDate,
                transform: DateConverter.display,
            },
        ],
        allowAdd: true,
        filters: [
            {
                label: 'Titre',
                property: ArticleProperties.title,
            },
            {
                label: 'Auteur(e)',
                property: `${ArticleProperties.author}.id`,
                type: InputType.entity,
                options: {
                    label: ArticleAuthorProperties.displayName,
                    resource: ArticleAuthor.resourcePath,
                },
            },
            {
                label: 'Catégorie',
                property: `${ArticleProperties.category}.id`,
                type: InputType.entity,
                options: {
                    label: ArticleCategoryProperties.title,
                    resource: ArticleCategory.resourcePath,
                },
            },
        ],
        defaultOrder: {
            property: ArticleProperties.publicationStartDate,
            order: 'desc',
        },
    },
    actions: {
        delete: true,
    },
};

export default Article;
export { ArticleProperties };

export const getObjectProperty = (
    obj: any,
    accessor: string,
    transform?: (result: string) => string,
    defaultValue?: any,
) => {
    defaultValue = defaultValue === undefined ? '' : defaultValue;
    const props = accessor.split('.');
    let result = obj;
    for (let i = 0; i < props.length; i++) result = result[props[i]] ?? defaultValue;
    return typeof transform == 'function' ? transform(result) : result;
};

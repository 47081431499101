import { UserType } from '../types/UserType';

class User implements UserType {
    id: number | undefined;
    roles: string[];
    username: string | undefined;

    constructor() {
        this.roles = [];
    }

    hasRole = (roles: string | string[]): boolean => {
        let access = false;
        roles = typeof roles === 'string' ? [roles] : roles;

        roles.every((role) => {
            if (this.roles.includes(role)) {
                access = true;
                return false;
            }
            return true;
        });

        return access;
    };

    loadFromStorage = (userJson: string | null) => {
        if (userJson) {
            const data = JSON.parse(userJson);
            this.id = data.id;
            this.roles = data.roles;
            this.username = data.username;
        }
    };
}

export default User;

import { roles } from 'config/config';
import { Route } from 'react-router-dom';
import LiveSession from 'config/resources/LiveSession';
import LiveSessionStreamScreen from 'screens/LiveSessionStreamScreen';
import { UserType } from 'm6BoBuilder';

const generateCustomRouteList = (user: UserType): JSX.Element[] => {
    const customRouteList: JSX.Element[] = [];

    if (user.hasRole([roles.ROLE_ADMIN, roles.ROLE_COACH_CB])) {
        customRouteList.push(
            <Route path={`/${LiveSession.resourcePath}/:id/stream`} element={<LiveSessionStreamScreen />} />,
        );
    }
    return customRouteList;
};

export default generateCustomRouteList;

import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { ActionType, ActionTypeProps } from '../../types/ActionsType';

const LinkAction: ActionType = ({ config, id }: ActionTypeProps): JSX.Element => {
    return (
        <IconButton
            component={Link}
            key={config.label + id}
            to={config.link.replace(':id', id.toString())}
            sx={{ marginLeft: '10px' }}
        >
            {config.icon}
        </IconButton>
    );
};

export default LinkAction;

import { FormInput, FormSubform, FormElementType, SubFormType, InputType } from 'm6BoBuilder';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import SubFormChallengeSessionVideo from './SubFormChallengeSessionVideo';

import { ChallengeSessionProperties, ChallengeSessionPlaceHolder } from 'config/resources/ChallengeSession';

const SubFormChallengeSession = ({ position, entity, updateEntity, configLine }: SubFormType): JSX.Element => {
    const [subEntity, setSubEntity] = useState({
        ...ChallengeSessionPlaceHolder,
        ...entity[configLine.property][position],
    });

    const configWeek: FormElementType = {
        label: 'Numéro de semaine',
        property: ChallengeSessionProperties.week,
        type: InputType.integer,
    };

    const configVideos: FormElementType = {
        label: 'Videos',
        property: 'challengeSessionVideos',
        type: InputType.subform,
        options: {
            subform: SubFormChallengeSessionVideo,
            multiple: true,
            sortable: {
                weight: 'position',
            },
        },
    };

    useEffect(() => {
        setSubEntity(subEntity);
    }, [entity]);

    const updateSubEntity = (updatedSubEntity: Record<string, any>) => {
        const values = entity[configLine.property];
        values[position] = updatedSubEntity;
        setSubEntity(updatedSubEntity);

        //step required, trigger useStates
        const updatedEntity = { ...entity, [configLine.property]: values };
        updateEntity(updatedEntity, configLine.property);
    };
    return (
        <Grid container spacing={3}>
            <Grid item xs={10}>
                <FormInput entity={subEntity} updateEntity={updateSubEntity} configLine={configWeek} />
            </Grid>
            <Grid item xs={10}>
                <FormSubform entity={subEntity} updateEntity={updateSubEntity} configLine={configVideos} />
            </Grid>
        </Grid>
    );
};

export default SubFormChallengeSession;

import MediaType from '../types/MediaType';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import imagePlaceholder from '../images/no-image.jpg';
const ImgContainer = styled(Box)(() => ({
    minWidth: 100,
    position: 'relative',
    '& img': {
        maxWidth: 200,
        maxHeight: 200,
        objectFit: 'cover',
        objectPosition: 'center',
    },
}));
class ImageConverter {
    static display = (media: MediaType): JSX.Element => {
        const url = media.medium ? process.env.REACT_APP_API_URL + media.medium : imagePlaceholder;
        return (
            <ImgContainer>
                <img src={url} />
            </ImgContainer>
        );
    };
}

export default ImageConverter;

import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { TextField, Stack, CardActions, Box, CardContent, CardHeader, Button } from '@mui/material';
import { styled } from '@mui/material';
import { AxiosResponse } from 'axios';

import { ApiContext, ApiLoading, UserContext } from '../AppBoBuilder';
import CustomLoader from '../components/CustomLoader';
import ApiService from '../services/ApiService';
import MainCard from '../berry/ui-component/cards/MainCard';
import { ReactComponent as Logo } from '../images/logo.svg';
import User from '../services/User';

const MainContainer: any = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    width: '100%',
    height: '100vh',
    position: 'relative',
}));

const LogoStyled = styled(Logo)(({ theme }) => ({
    width: 200,
    '& .croq': {
        fill: theme.palette.secondary.main,
    },
    '& .bo': {
        fill: theme.palette.text.primary,
    },
}));

//state type
type State = {
    username: string;
    password: string;
    isButtonDisabled: boolean;
    error: string;
};

const initialState: State = {
    username: '',
    password: '',
    isButtonDisabled: true,
    error: '',
};

type LoggingProps = {
    setLoggedIn: Dispatch<SetStateAction<boolean>>;
};

type loginResponse = {
    logged: boolean;
    userInfos?: {
        id: number;
        roles: Array<string>;
    };
    token?: string;
};

const Login = ({ setLoggedIn }: LoggingProps): JSX.Element => {
    // const classes = useStyles();
    const apiService: ApiService = useContext(ApiContext);
    const user: User = useContext(UserContext);
    const isApiLoading = useContext(ApiLoading);
    const [username, setUsername] = useState(initialState.username);
    const [password, setPassword] = useState(initialState.password);
    const [error, setError] = useState(initialState.error);
    const [isButtonDisabled, setIsButtonDisabled] = useState(initialState.isButtonDisabled);

    useEffect(() => {
        if (username.trim() && password.trim()) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [username, password]);

    const handleLogin = () => {
        localStorage.removeItem('user');
        apiService
            .login(username, password)
            .then(({ logged, userInfos }: loginResponse) => {
                if (logged && userInfos !== undefined) {
                    user.id = userInfos.id;
                    user.username = username;
                    user.roles = userInfos.roles;
                    localStorage.setItem('user', JSON.stringify(user));
                    setLoggedIn(logged);
                    //Hack, need to manage user reset in local storage
                    window.location.reload();
                } else {
                    setError('Incorrect username or password');
                }
            })
            .catch((data: AxiosResponse) => {
                setError('Incorrect username or password');
                console.log(data);
            });
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.keyCode === 13 || event.which === 13) {
            isButtonDisabled || handleLogin();
        }
    };

    const handleUsernameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setPassword(event.target.value);
    };

    return (
        <MainContainer>
            <form noValidate autoComplete="off">
                <MainCard
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <CardHeader title={<LogoStyled />} sx={{ textAlign: 'center' }} />
                    <CardContent>
                        <div>
                            <TextField
                                error={error !== ''}
                                fullWidth
                                id="username"
                                type="email"
                                label="Identifiant"
                                placeholder="Identifiant"
                                margin="normal"
                                onChange={handleUsernameChange}
                                onKeyPress={handleKeyPress}
                            />
                            <TextField
                                error={error !== ''}
                                fullWidth
                                id="password"
                                type="password"
                                label="Mot de passe"
                                placeholder="Mot de passe"
                                margin="normal"
                                helperText={error}
                                onChange={handlePasswordChange}
                                onKeyPress={handleKeyPress}
                            />
                        </div>
                    </CardContent>
                    <CardActions>
                        {isApiLoading ? (
                            <Stack alignItems="center" width={'100%'}>
                                <CustomLoader />
                            </Stack>
                        ) : (
                            <Button
                                variant="contained"
                                size="large"
                                color="secondary"
                                onClick={handleLogin}
                                disabled={isButtonDisabled}
                            >
                                Se connecter
                            </Button>
                        )}
                    </CardActions>
                </MainCard>
            </form>
        </MainContainer>
    );
};

export default Login;

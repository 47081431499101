import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import frLocale from 'date-fns/locale/fr';

import DateConverter from '../../converters/DateConverter';
import { FormElementPropsType } from '../../types/forms/FormElementPropsType';

const FormDate = ({ entity, updateEntity, configLine, fieldSize = 'medium' }: FormElementPropsType): JSX.Element => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const handleDateChange = (date: Date | null) => {
        entity[configLine.property] = date ? date.toDateString() : null;
        updateEntity(entity, configLine.property);
        setSelectedDate(date);
    };

    useEffect(() => {
        if (entity[configLine.property]) {
            setSelectedDate(DateConverter.convert(entity[configLine.property]));
        }
    }, [entity]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
            <DatePicker
                label={configLine.label}
                toolbarFormat={'DD-MM-YYYY'}
                value={selectedDate}
                renderInput={(params) => <TextField size={fieldSize} {...params} />}
                onChange={handleDateChange}
            />
        </LocalizationProvider>
    );
};
export default FormDate;

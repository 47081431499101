import * as yup from 'yup';
import { Cast } from '@mui/icons-material';
import { InputType, ResourceType, DateConverter, SelectRenderType, LinkAction } from 'm6BoBuilder';
import Live, { LiveProperties } from './Live';
import Video, { VideoProperties } from './Video';

const LiveSessionProperties = {
    id: 'id',
    live: 'live',
    sessionDate: 'sessionDate',
    room: 'room',
    state: 'state',
    altVideo: 'altVideo',
};

const LiveSession: ResourceType = {
    resourcePath: 'live_sessions',
    placeHolder: {
        [LiveSessionProperties.id]: 0,
        [LiveSessionProperties.live]: null,
        [LiveSessionProperties.sessionDate]: new Date(),
        [LiveSessionProperties.room]: '',
        [LiveSessionProperties.state]: 'PENDING',
        [LiveSessionProperties.altVideo]: null,
    },

    validationSchema: yup.object({}),

    configForm: {
        label: 'Session de live',
        listInputs: [
            {
                label: Live.configForm.label,
                property: LiveSessionProperties.live,
                type: InputType.entity,
                options: {
                    resource: Live.resourcePath,
                },
            },
            {
                label: 'Date de session',
                property: LiveSessionProperties.sessionDate,
                type: InputType.datetime,
            },
            {
                label: 'Room Twilio',
                property: LiveSessionProperties.room,
            },
            {
                label: 'Statut',
                property: LiveSessionProperties.state,
                type: InputType.select,
                options: {
                    choices: [
                        { value: 'PENDING', label: 'En attente' },
                        { value: 'STREAMING', label: 'En cours' },
                        { value: 'FINISHED', label: 'Terminé' },
                        { value: 'CANCELLED', label: 'Annulé' },
                    ],
                    render: SelectRenderType.select,
                },
            },
            {
                label: 'Vidéo Alternative',
                property: LiveSessionProperties.altVideo,
                type: InputType.entity,
                options: {
                    resource: Video.resourcePath,
                },
            },
        ],
    },

    configList: {
        label: 'Sessions de lives',
        listProperties: [
            {
                label: '#',
                property: LiveSessionProperties.id,
                sortable: true,
                styles: {
                    width: '7%',
                },
            },
            {
                label: Live.configForm.label,
                property: `${LiveSessionProperties.live}.${LiveProperties.title}`,
                sortable: true,
            },
            {
                label: 'Date de session',
                property: LiveSessionProperties.sessionDate,
                sortable: true,
                transform: (date: string) => DateConverter.display(date, true),
            },
            {
                label: 'Room Twilio',
                property: LiveSessionProperties.room,
            },
            {
                label: 'Statut',
                property: LiveSessionProperties.state,
            },
            {
                label: 'Vidéo alternative',
                property: `${LiveSessionProperties.altVideo}.${VideoProperties.title}`,
            },
        ],
        allowAdd: false,
        defaultOrder: {
            property: 'sessionDate',
            order: 'desc',
        },
    },
    actions: {
        delete: false,
        customs: [
            {
                config: {
                    label: 'Stream',
                    link: `/live_sessions/:id/stream`,
                    icon: <Cast color="primary" />,
                },
                component: LinkAction,
            },
        ],
    },
};

export default LiveSession;
export { LiveSessionProperties };

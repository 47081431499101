class DateConverter {
    static convert = (value: string): Date => {
        return new Date(value);
    };
    static invert = (date: Date): string => {
        return date.toISOString();
    };
    static display = (value: string, includeTime = false): string => {
        if (value === '-' || value === '') return '-';
        const date = new Date(value);
        return includeTime
            ? date.toLocaleString('fr', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
              })
            : date.toLocaleDateString();
    };
}

export default DateConverter;

import React, { useEffect, useState } from 'react';
import ApiService from './services/ApiService';
import ScreenSwitcher from './components/navigation/ScreenSwitcher';
import { useLocation, useNavigate } from 'react-router';
import ThemeCustomization from './berry/themes';
import User from './services/User';
import { ConfigType } from './types/ConfigType';

const user: User = new User();
user.loadFromStorage(localStorage.getItem('user'));

export const ApiContext = React.createContext<ApiService>({} as ApiService);
export const UserContext = React.createContext(user);
export const ApiLoading = React.createContext(false);
export const ConfigContext = React.createContext<ConfigType | null>(null);

type AppBoBuilderType = {
    config: ConfigType;
};

const apiService = new ApiService();

const AppBoBuilder = ({ config }: AppBoBuilderType): JSX.Element => {
    apiService.loadConfig(config);
    const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token') !== null);
    const [isApiLoading, setIsApiLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setLoggedIn(apiService.loggedIn);
        apiService.setIsLoadingCallback(setIsApiLoading);
        apiService.setLoggedInCallback(setLoggedIn);
    }, []);

    const handleLogout = () => {
        localStorage.clear();
        setLoggedIn(false);
        navigate('/login');
    };

    useEffect(() => {
        if (!loggedIn) {
            navigate('/login');
        } else {
            // location.pathname returns the previous screen
            // after login we go to /
            // on refresh we go to the previously stored path
            if (location.pathname === '/login') {
                navigate('/');
            }
        }
    }, [loggedIn]);

    return (
        <ThemeCustomization>
            <ApiContext.Provider value={apiService}>
                <UserContext.Provider value={user}>
                    <ConfigContext.Provider value={config}>
                        <ApiLoading.Provider value={isApiLoading}>
                            <ScreenSwitcher loggedIn={loggedIn} setLoggedIn={setLoggedIn} handleLogout={handleLogout} />
                        </ApiLoading.Provider>
                    </ConfigContext.Provider>
                </UserContext.Provider>
            </ApiContext.Provider>
        </ThemeCustomization>
    );
};

export default AppBoBuilder;

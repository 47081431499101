import Pagination from '@mui/material/Pagination';
import React from 'react';
import { Box, Grid } from '@mui/material';

type PaginatorProps = {
    maxPage: number;
    currentPage: number;
    updateCurrentPage: (selectedPage: number) => void;
};

const Paginator = ({ maxPage, currentPage, updateCurrentPage }: PaginatorProps): JSX.Element => {
    const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        updateCurrentPage(value);
    };
    if (maxPage <= 1) {
        return <></>;
    }
    return (
        <Grid container justifyContent="center" direction="row">
            <Box m={2}>
                <Pagination
                    count={maxPage}
                    page={currentPage}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                />
            </Box>
        </Grid>
    );
};
export default Paginator;

import { NavigationType } from 'm6BoBuilder';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CastIcon from '@mui/icons-material/Cast';
import Article from './resources/Article';
import User from './resources/User';

import { EmojiEvents, FitnessCenter, PeopleAlt } from '@mui/icons-material';
import BodyPart from './resources/BodyPart';
import Level from './resources/Level';
import Video from './resources/Video';
import Coach from './resources/Coach';
import Program from './resources/Program';
import Goal from './resources/Goal';
import Sport from './resources/Sport';
import Equipment from './resources/Equipment';
import LiveSession from './resources/LiveSession';
import Live from './resources/Live';
import { roles } from './config';
import Challenge from './resources/Challenge';
import PromotionalCommunication from './resources/PromotionalCommunication';
import PromotionalMedia from './resources/PromotionalMedia';
import { IconFiles } from '@tabler/icons';
import ArticleCategory from './resources/ArticleCategory';
import ArticleAuthor from './resources/ArticleAuthor';

const navigation: NavigationType = [
    {
        title: 'Accueil',
        link: '/',
        icon: DashboardIcon,
        accessControl: [roles.ROLE_ADMIN],
    },
    {
        title: 'Animations',
        icon: EmojiEvents,
        accessControl: [roles.ROLE_ADMIN],
        subnav: [
            {
                title: Challenge.configList.label,
                link: `/${Challenge.resourcePath}`,
                accessControl: [roles.ROLE_ADMIN],
            },
        ],
    },
    {
        title: 'Programmes',
        icon: FitnessCenter,
        accessControl: [roles.ROLE_ADMIN],
        subnav: [
            {
                title: Program.configList.label,
                link: `/${Program.resourcePath}`,
                singular: 'un programme',
                accessControl: [roles.ROLE_ADMIN],
            },
            {
                title: Coach.configList.label,
                link: `/${Coach.resourcePath}`,
                singular: 'un coach',
                accessControl: [roles.ROLE_ADMIN],
            },
            {
                title: Video.configList.label,
                link: `/${Video.resourcePath}`,
                singular: 'une vidéo',
                accessControl: [roles.ROLE_ADMIN],
            },
            {
                title: Sport.configList.label,
                link: `/${Sport.resourcePath}`,
                singular: 'un sport',
                accessControl: [roles.ROLE_ADMIN],
            },
            {
                title: Goal.configList.label,
                link: `/${Goal.resourcePath}`,
                singular: 'un objectif',
                accessControl: [roles.ROLE_ADMIN],
            },
            {
                title: BodyPart.configList.label,
                link: `/${BodyPart.resourcePath}`,
                singular: 'une partie du corps',
                accessControl: [roles.ROLE_ADMIN],
            },
            {
                title: Level.configList.label,
                link: `/${Level.resourcePath}`,
                singular: 'un niveau',
                accessControl: [roles.ROLE_ADMIN],
            },
            {
                title: Equipment.configList.label,
                link: `/${Equipment.resourcePath}`,
                singular: 'un équipement',
                accessControl: [roles.ROLE_ADMIN],
            },
            {
                title: LiveSession.configList.label,
                link: `/${LiveSession.resourcePath}`,
                singular: 'une session de live',
                accessControl: [roles.ROLE_ADMIN, roles.ROLE_COACH_CB],
            },
        ],
    },
    {
        title: LiveSession.configList.label,
        link: `/${LiveSession.resourcePath}`,
        singular: 'une session de live',
        icon: CastIcon,
        accessControl: [roles.ROLE_ADMIN, roles.ROLE_COACH_CB],
    },
    {
        title: Live.configList.label,
        link: `/${Live.resourcePath}`,
        singular: 'un live',
        icon: CastIcon,
        accessControl: [roles.ROLE_ADMIN, roles.ROLE_COACH_CB],
    },
    {
        title: 'Contenus',
        icon: IconFiles,
        accessControl: [roles.ROLE_ADMIN],
        subnav: [
            {
                title: Article.configList.label,
                link: `/${Article.resourcePath}`,
                singular: 'un article',
                accessControl: [roles.ROLE_ADMIN],
            },
            {
                title: ArticleCategory.configList.label,
                link: `/${ArticleCategory.resourcePath}`,
                singular: 'une categorie',
                accessControl: [roles.ROLE_ADMIN],
            },
            {
                title: ArticleAuthor.configList.label,
                link: `/${ArticleAuthor.resourcePath}`,
                singular: 'un auteur',
                accessControl: [roles.ROLE_ADMIN],
            },
            {
                title: 'Bandeaux promo',
                link: `/${PromotionalCommunication.resourcePath}`,
                singular: 'un bandeau promo',
                accessControl: [roles.ROLE_ADMIN],
            },
            {
                title: 'Medias promotionnels',
                link: `/${PromotionalMedia.resourcePath}`,
                singular: 'un media promo',
                accessControl: [roles.ROLE_ADMIN],
            },
        ],
    },
    {
        title: User.configList.label,
        link: `/${User.resourcePath}`,
        icon: PeopleAlt,
        singular: 'un utilisateur',
        accessControl: [roles.ROLE_ADMIN],
    },
];

export default navigation;

import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme, Theme } from '@mui/material/styles';
import { Box, Container, CssBaseline, useMediaQuery } from '@mui/material';

// project imports
import { MainAppBar } from './MainAppBar';
import Sidebar from '../../components/layout/Sidebar';
import Flash from '../../components/Flash';
import useConfig from '../../berry/hooks/useConfig';
import { drawerWidth } from '../../berry/store/constant';
import PageHeader from '../../components/PageHeader';
// assets
import { useState } from 'react';

interface MainStyleProps {
    theme: Theme;
    open: boolean;
}

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }: MainStyleProps) => ({
    borderRadius: `${theme.shape.borderRadius}px`,
    marginBottom: '20px',
    ...theme.typography.mainContent,
    ...(!open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter,
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`,
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px',
        },
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter,
        }),
        marginLeft: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
        },
    }),
}));

// ==============================|| MAIN LAYOUT ||============================== //

const Layout = ({ handleLogout }: { handleLogout: () => void }): JSX.Element => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const [drawerOpen, setDrawerOpen] = useState(!matchUpMd ? true : false);
    const { container } = useConfig();

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <MainAppBar handleLogout={handleLogout} toggleDrawer={toggleDrawer} />

            <Sidebar drawerOpen={drawerOpen} onClose={toggleDrawer} />

            {/* main content */}
            <Main theme={theme} open={drawerOpen}>
                {/* breadcrumb */}
                {container && (
                    <Container maxWidth="lg">
                        <Flash />
                        <PageHeader />
                        <Outlet />
                    </Container>
                )}
                {!container && (
                    <>
                        <Flash />
                        <PageHeader />
                        <Outlet />
                    </>
                )}
            </Main>
        </Box>
    );
};

export default Layout;

import Article from './resources/Article';
import User from './resources/User';
import { ResourceScreenType } from 'm6BoBuilder';
import BodyPart from './resources/BodyPart';
import Level from './resources/Level';
import Coach from './resources/Coach';
import Video from './resources/Video';
import Goal from './resources/Goal';
import Program from './resources/Program';
import Sport from './resources/Sport';
import Challenge from './resources/Challenge';
import Live from './resources/Live';
import Equipment from './resources/Equipment';
import LiveSession from './resources/LiveSession';
import { roles } from './config';
import PromotionalCommunication from './resources/PromotionalCommunication';
import PromotionalMedia from './resources/PromotionalMedia';
import ArticleCategory from './resources/ArticleCategory';
import ArticleAuthor from './resources/ArticleAuthor';

const screens: ResourceScreenType[] = [
    {
        resource: Program,
        accessControl: {
            list: [roles.ROLE_ADMIN],
            create: [roles.ROLE_ADMIN],
            edit: [roles.ROLE_ADMIN],
            delete: [roles.ROLE_ADMIN],
        },
    },
    {
        resource: Challenge,
        accessControl: {
            list: [roles.ROLE_ADMIN],
            create: [roles.ROLE_ADMIN],
            edit: [roles.ROLE_ADMIN],
            delete: [roles.ROLE_ADMIN],
        },
    },
    {
        resource: Coach,
        accessControl: {
            list: [roles.ROLE_ADMIN],
            create: [roles.ROLE_ADMIN],
            edit: [roles.ROLE_ADMIN],
            delete: [roles.ROLE_ADMIN],
        },
    },
    {
        resource: Video,
        accessControl: {
            list: [roles.ROLE_ADMIN],
            create: [roles.ROLE_ADMIN],
            edit: [roles.ROLE_ADMIN],
            delete: [roles.ROLE_ADMIN],
        },
    },
    {
        resource: BodyPart,
        accessControl: {
            list: [roles.ROLE_ADMIN],
            create: [roles.ROLE_ADMIN],
            edit: [roles.ROLE_ADMIN],
            delete: [roles.ROLE_ADMIN],
        },
    },
    {
        resource: Goal,
        accessControl: {
            list: [roles.ROLE_ADMIN],
            create: [roles.ROLE_ADMIN],
            edit: [roles.ROLE_ADMIN],
            delete: [roles.ROLE_ADMIN],
        },
    },
    {
        resource: Level,
        accessControl: {
            list: [roles.ROLE_ADMIN],
            create: [roles.ROLE_ADMIN],
            edit: [roles.ROLE_ADMIN],
            delete: [roles.ROLE_ADMIN],
        },
    },
    {
        resource: Article,
        accessControl: {
            list: [roles.ROLE_ADMIN],
            create: [roles.ROLE_ADMIN],
            edit: [roles.ROLE_ADMIN],
            delete: [roles.ROLE_ADMIN],
        },
    },
    {
        resource: ArticleCategory,
        accessControl: {
            list: [roles.ROLE_ADMIN],
            create: [roles.ROLE_ADMIN],
            edit: [roles.ROLE_ADMIN],
            delete: [roles.ROLE_ADMIN],
        },
    },
    {
        resource: Sport,
        accessControl: {
            list: [roles.ROLE_ADMIN],
            create: [roles.ROLE_ADMIN],
            edit: [roles.ROLE_ADMIN],
            delete: [roles.ROLE_ADMIN],
        },
    },
    {
        resource: Challenge,
        accessControl: {
            list: [roles.ROLE_ADMIN],
            create: [roles.ROLE_ADMIN],
            edit: [roles.ROLE_ADMIN],
            delete: [roles.ROLE_ADMIN],
        },
    },
    {
        resource: Live,
        accessControl: {
            list: [roles.ROLE_ADMIN],
            create: [roles.ROLE_ADMIN],
            edit: [roles.ROLE_ADMIN],
            delete: [roles.ROLE_ADMIN],
        },
    },
    {
        resource: LiveSession,
        accessControl: {
            list: [roles.ROLE_ADMIN, roles.ROLE_COACH_CB],
            create: [roles.ROLE_ADMIN],
            edit: [roles.ROLE_ADMIN, roles.ROLE_COACH_CB],
            delete: [roles.ROLE_ADMIN],
        },
    },
    {
        resource: Equipment,
        accessControl: {
            list: [roles.ROLE_ADMIN],
            create: [roles.ROLE_ADMIN],
            edit: [roles.ROLE_ADMIN],
            delete: [roles.ROLE_ADMIN],
        },
    },
    {
        resource: User,
        accessControl: {
            list: [roles.ROLE_ADMIN],
            create: [roles.ROLE_ADMIN],
            edit: [roles.ROLE_ADMIN],
            delete: [roles.ROLE_ADMIN],
        },
    },
    {
        resource: PromotionalCommunication,
        accessControl: {
            list: [roles.ROLE_ADMIN],
            create: [roles.ROLE_ADMIN],
            edit: [roles.ROLE_ADMIN],
            delete: [roles.ROLE_ADMIN],
        },
    },
    {
        resource: PromotionalMedia,
        accessControl: {
            list: [roles.ROLE_ADMIN],
            create: [roles.ROLE_ADMIN],
            edit: [roles.ROLE_ADMIN],
            delete: [roles.ROLE_ADMIN],
        },
    },
    {
        resource: ArticleAuthor,
        accessControl: {
            list: [roles.ROLE_ADMIN],
            create: [roles.ROLE_ADMIN],
            edit: [roles.ROLE_ADMIN],
            delete: [roles.ROLE_ADMIN],
        },
    },
];
export default screens;

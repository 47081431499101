import * as yup from 'yup';
import { InputType, ResourceType } from 'm6BoBuilder';
import Level, { LevelProperties } from './Level';
import Goal from './Goal';
import Program from './Program';
import Challenge from './Challenge';

const UserProperties = {
    id: 'id',
    username: 'username',
    firstname: 'firstname',
    lastname: 'lastname',
    birthdate: 'birthdate',
    level: 'level',
    goals: 'goals',
    goalWeight: 'goalWeight',
    planningMode: 'planningMode',
    program: 'program',
    challenge: 'challenge',
};

const User: ResourceType = {
    resourcePath: 'user_details',
    placeHolder: {
        [UserProperties.username]: '',
        [UserProperties.firstname]: '',
        [UserProperties.lastname]: '',
        [UserProperties.birthdate]: '',
        [UserProperties.level]: '',
        [UserProperties.goals]: [],
        [UserProperties.goalWeight]: 0.0,
        [UserProperties.planningMode]: '',
        [UserProperties.program]: '',
        [UserProperties.challenge]: '',
    },
    validationSchema: yup.object({
        //title: yup.string().required('Ce champ est obligatoire'),
    }),
    configForm: {
        label: 'Utilisateur',
        listInputs: [
            {
                label: 'Login',
                property: UserProperties.username,
            },
            {
                label: 'Prénom',
                property: UserProperties.firstname,
            },
            {
                label: 'Nom',
                property: UserProperties.lastname,
            },
            {
                label: 'Date de naissance',
                property: UserProperties.birthdate,
                type: InputType.date,
            },
            {
                label: Level.configForm.label,
                property: UserProperties.level,
                type: InputType.entity,
                options: {
                    resource: Level.resourcePath,
                },
            },
            {
                label: 'Objectif',
                property: UserProperties.goals,
                type: InputType.entity,
                options: {
                    resource: Goal.resourcePath,
                    multiple: true,
                },
            },
            {
                label: 'Objectif de poid',
                property: UserProperties.goalWeight,
                type: InputType.float,
            },
            {
                label: 'Mode',
                property: UserProperties.planningMode,
            },
            {
                label: 'Programme',
                property: UserProperties.program,
                type: InputType.entity,
                options: {
                    resource: Program.resourcePath,
                },
            },
            {
                label: 'Challenge',
                property: UserProperties.challenge,
                type: InputType.entity,
                options: {
                    resource: Challenge.resourcePath,
                },
            },
        ],
    },

    configList: {
        label: 'Utilisateurs',
        listProperties: [
            {
                label: '#',
                property: UserProperties.id,
                sortable: true,
                styles: {
                    width: '7%',
                },
            },
            {
                label: 'Nom',
                property: UserProperties.username,
            },
            {
                label: 'Niveau',
                property: `${UserProperties.level}.${LevelProperties.title}`,
            },
        ],
        allowAdd: false,
        filters: [
            {
                label: 'username',
                property: UserProperties.username,
            },
        ],
    },
};

export type UserType = {
    email: string;
    fullname: string;
    roles: string[];
};

export default User;
export { UserProperties };

import { InputType, ResourceType, ImageConverter, TagConverter, SelectRenderType } from 'm6BoBuilder';
import * as yup from 'yup';
import Sport, { SportProperties } from './Sport';
import Equipment from './Equipment';
import Coach, { CoachProperties } from './Coach';

const LiveProperties = {
    id: 'id',
    title: 'title',
    dayOfWeek: 'dayOfWeek',
    hour: 'hour',
    photo: 'photo',
    coach: 'coach',
    sport: 'sport',
    duration: 'duration',
    equipments: 'equipments',
};

const Live: ResourceType = {
    resourcePath: 'lives',
    placeHolder: {
        [LiveProperties.id]: 0,
        [LiveProperties.title]: '',
        [LiveProperties.hour]: 0,
        [LiveProperties.dayOfWeek]: 1,
        [LiveProperties.duration]: 1,
        [LiveProperties.photo]: null,
        [LiveProperties.coach]: null,
        [LiveProperties.sport]: null,
        [LiveProperties.equipments]: [],
    },

    validationSchema: yup.object({
        [LiveProperties.title]: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Live',
        listInputs: [
            {
                label: 'Nom',
                property: LiveProperties.title,
            },
            {
                label: 'Heure',
                property: LiveProperties.hour,
                type: InputType.integer,
            },
            {
                label: 'Jour de la semaine',
                property: LiveProperties.dayOfWeek,
                type: InputType.select,
                options: {
                    choices: [
                        { value: 1, label: 'Lundi' },
                        { value: 2, label: 'Mardi' },
                        { value: 3, label: 'Mercredi' },
                        { value: 4, label: 'Jeudi' },
                        { value: 5, label: 'Vendredi' },
                        { value: 6, label: 'Samedi' },
                        { value: 7, label: 'Dimanche' },
                    ],
                    render: SelectRenderType.select,
                },
            },
            {
                label: 'Durée',
                property: LiveProperties.duration,
                type: InputType.integer,
            },
            {
                label: 'Photo',
                property: LiveProperties.photo,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: Coach.configForm.label,
                property: LiveProperties.coach,
                type: InputType.entity,
                options: {
                    resource: Coach.resourcePath,
                    label: CoachProperties.signature,
                },
            },
            {
                label: Sport.configForm.label,
                property: LiveProperties.sport,
                type: InputType.entity,
                options: {
                    resource: Sport.resourcePath,
                },
            },
            {
                label: Equipment.configForm.label,
                property: LiveProperties.equipments,
                type: InputType.entity,
                options: {
                    resource: Equipment.resourcePath,
                    multiple: true,
                },
            },
        ],
    },

    configList: {
        label: 'Lives',
        listProperties: [
            {
                label: '#',
                property: LiveProperties.id,
                sortable: true,
                styles: {
                    width: '7%',
                },
            },
            {
                label: 'Image',
                property: LiveProperties.photo,
                transform: ImageConverter.display,
            },
            {
                label: 'Titre',
                property: LiveProperties.title,
                sortable: true,
            },
            {
                label: Coach.configForm.label,
                property: `${LiveProperties.coach}.${CoachProperties.signature}`,
            },
            {
                label: Sport.configForm.label,
                property: `${LiveProperties.sport}.${SportProperties.title}`,
            },
            {
                label: Equipment.configList.label,
                property: LiveProperties.equipments,
                transform: (elements: []) => elements.map((elt) => TagConverter.display(elt, 'title')),
            },
        ],
        allowAdd: true,
    },
    actions: {
        delete: true,
    },
};

export default Live;
export { LiveProperties };

import { InputType, ResourceType } from 'm6BoBuilder';
import * as yup from 'yup';

const GoalProperties = {
    id: 'id',
    title: 'title',
    description: 'description',
    subscriptionPhoto: 'subscriptionPhoto',
};

const Goal: ResourceType = {
    resourcePath: 'goals',
    placeHolder: {
        [GoalProperties.id]: 0,
        [GoalProperties.title]: '',
        [GoalProperties.description]: '',
        [GoalProperties.subscriptionPhoto]: null,
    },

    validationSchema: yup.object({
        [GoalProperties.title]: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Objectif',
        listInputs: [
            {
                label: 'Nom',
                property: GoalProperties.title,
            },
            {
                label: 'Subscription Photo',
                property: GoalProperties.subscriptionPhoto,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Description',
                property: GoalProperties.description,
                options: {
                    textArea: true,
                },
            },
        ],
    },

    configList: {
        label: 'Objectifs',
        listProperties: [
            {
                label: '#',
                property: GoalProperties.id,
                sortable: true,
                styles: {
                    width: '7%',
                },
            },
            {
                label: 'Titre',
                property: GoalProperties.title,
                sortable: true,
            },
        ],
        allowAdd: true,
    },
    actions: {
        delete: true,
    },
};

export default Goal;
export { GoalProperties };

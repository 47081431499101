import { InputType, ResourceType, ImageConverter } from 'm6BoBuilder';
import * as yup from 'yup';

const EquipmentProperties = {
    id: 'id',
    title: 'title',
    photo: 'photo',
};

const Equipment: ResourceType = {
    resourcePath: 'equipment',
    placeHolder: {
        [EquipmentProperties.id]: 0,
        [EquipmentProperties.title]: '',
        [EquipmentProperties.photo]: null,
    },

    validationSchema: yup.object({
        [EquipmentProperties.title]: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Equipement',
        listInputs: [
            {
                label: 'Nom',
                property: EquipmentProperties.title,
            },
            {
                label: 'Photo',
                property: EquipmentProperties.photo,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
        ],
    },

    configList: {
        label: 'Equipements',
        listProperties: [
            {
                label: '#',
                property: EquipmentProperties.id,
                sortable: true,
                styles: {
                    width: '7%',
                },
            },
            {
                label: 'Photo',
                property: EquipmentProperties.photo,
                transform: ImageConverter.display,
                styles: {
                    width: '10%',
                },
            },
            {
                label: 'Titre',
                property: EquipmentProperties.title,
                sortable: true,
            },
        ],
        allowAdd: true,
    },
    actions: {
        delete: true,
    },
};

export default Equipment;
export { EquipmentProperties };

import { FormEntity, FormElementType, SubFormType } from 'm6BoBuilder';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Video from 'config/resources/Video';
import { ProgramVideoProperties } from 'config/resources/ProgramVideo';
import { ChallengeSessionVideosPlaceHolder } from 'config/resources/ChallengeSession';

const SubFormChallengeSessionVideo = ({ position, entity, updateEntity, configLine }: SubFormType): JSX.Element => {
    const [subEntity, setSubEntity] = useState(
        entity[configLine.property][position] ?? ChallengeSessionVideosPlaceHolder,
    );
    const configVideo: FormElementType = {
        label: 'Vidéo',
        property: ProgramVideoProperties.video,
        options: {
            details: {
                path: 'coach.signature',
                prelabel: 'coach ',
            },
            resource: Video.resourcePath,
        },
    };

    useEffect(() => {
        setSubEntity(subEntity);
    }, [entity]);

    const updateSubEntity = (updatedSubEntity: Record<string, any>) => {
        const values = entity[configLine.property];
        values[position] = updatedSubEntity;
        setSubEntity(updatedSubEntity);

        //step required, trigger useStates
        const updatedEntity = { ...entity, [configLine.property]: values };
        updateEntity(updatedEntity, configLine.property);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={10}>
                <FormEntity entity={subEntity} updateEntity={updateSubEntity} configLine={configVideo} />
            </Grid>
        </Grid>
    );
};

export default SubFormChallengeSessionVideo;

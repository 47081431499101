import { InputType, ResourceType, ImageConverter } from 'm6BoBuilder';
import * as yup from 'yup';

const SportProperties = {
    id: 'id',
    title: 'title',
    met: 'met',
    photo: 'photo',
};

const Sport: ResourceType = {
    resourcePath: 'sports',
    placeHolder: {
        [SportProperties.id]: 0,
        [SportProperties.title]: '',
        [SportProperties.met]: 0,
        [SportProperties.photo]: null,
    },

    validationSchema: yup.object({
        [SportProperties.title]: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Sport',
        listInputs: [
            {
                label: 'Nom',
                property: SportProperties.title,
            },
            {
                label: 'MET',
                property: SportProperties.met,
                type: InputType.float,
            },
            {
                label: 'Photo',
                property: SportProperties.photo,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
        ],
    },

    configList: {
        label: 'Sports',
        listProperties: [
            {
                label: '#',
                property: SportProperties.id,
                sortable: true,
                styles: {
                    width: '7%',
                },
            },
            {
                label: 'Image',
                property: SportProperties.photo,
                transform: ImageConverter.display,
            },
            {
                label: 'Titre',
                property: SportProperties.title,
                sortable: true,
            },
            {
                label: 'MET',
                property: SportProperties.met,
                sortable: true,
            },
        ],
        allowAdd: true,
    },
    actions: {
        delete: true,
    },
};

export default Sport;
export { SportProperties };

import { useState, useContext, MouseEvent } from 'react';
import { useNavigate } from 'react-router';
import { Grid, Button, Fab, Box, Stack, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconTrash } from '@tabler/icons';
import { Save } from '@mui/icons-material';

import inputTypes from '../../types/forms/InputTypes';
import { FormElementType, InputType } from '../../types/forms/ConfigFormType';
import { EditFormType } from '../../types/forms/EditFormType';
import { CustomActionType } from '../../types/ActionsType';

import ApiService from '../../services/ApiService';
import { ApiContext } from '../../AppBoBuilder';
import { flashMessage } from '../Flash';
import DeleteModal from '../DeleteModal';

const ErrorLabel = styled(Typography)(({ theme }) => ({
    marginTop: 5,
    color: theme.palette.error.main,
}));

const BtnsContainer = styled(Box)(() => ({
    '& > *:not(:last-child)': {
        marginRight: 10,
    },
}));

const DefaultForm = ({
    entity,
    config,
    resourceType,
    errors,
    actions,
    handleEntityUpdate,
}: EditFormType): JSX.Element => {
    const [isDeleteModalVisible, setDeleteModal] = useState(false);
    const apiService: ApiService = useContext(ApiContext);
    const navigate = useNavigate();

    const showDeleteModal = () => (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event?.stopPropagation();
        setDeleteModal(true);
    };

    const handleDelete = async () => {
        await apiService.remove(resourceType, entity.id);
        flashMessage(`${config.label} #${entity.id} a bien été supprimée`);
        navigate(`/${resourceType}`);
        setDeleteModal(false);
    };

    return (
        <>
            <Grid container spacing={3} justifyContent={'start'}>
                {config.listInputs.map((configLine: FormElementType, index: number) => {
                    const FormElement = inputTypes[configLine.type ?? InputType.text];
                    const onError = errors[configLine.property];
                    return (
                        <Grid
                            item
                            xs={12}
                            md={12}
                            lg={configLine.gridItemSize ? configLine.gridItemSize : 8}
                            key={index}
                            sx={{ position: 'relative' }}
                        >
                            <FormElement
                                error={onError}
                                entity={entity}
                                updateEntity={handleEntityUpdate}
                                configLine={configLine}
                            />
                            {onError && <ErrorLabel variant={'subtitle2'}>{errors[configLine.property]}</ErrorLabel>}
                        </Grid>
                    );
                })}

                <Grid item xs={12}>
                    <Stack direction={'row'} justifyContent="space-between" alignItems={'center'}>
                        <BtnsContainer>
                            <Button color="primary" size="large" variant="contained" startIcon={<Save />} type="submit">
                                Enregistrer
                            </Button>
                            {actions?.customs && (
                                <>
                                    {actions.customs.map((customAction: CustomActionType, index) => {
                                        const Action = customAction.component;
                                        return (
                                            <Action
                                                key={`action_${index}`}
                                                config={customAction.config || {}}
                                                id={entity.id}
                                            />
                                        );
                                    })}
                                </>
                            )}
                            {actions?.delete && entity.id > 0 && (
                                <Tooltip title="Supprimer">
                                    <Fab onClick={showDeleteModal()} color="error">
                                        <IconTrash />
                                    </Fab>
                                </Tooltip>
                            )}
                        </BtnsContainer>
                    </Stack>
                </Grid>
            </Grid>

            <DeleteModal
                modalOpen={isDeleteModalVisible}
                closeModal={() => setDeleteModal(false)}
                handleDelete={handleDelete}
            />
        </>
    );
};

export default DefaultForm;

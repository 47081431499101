import { Modal, Box, TextField, FormControlLabel, Checkbox, Button } from '@mui/material';
import React from 'react';
import { useCallback, useMemo, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './FormEditor.css';

const isTargetBlankRegex = /\?isBlank=true/;
const Link = Quill.import('formats/link');

class CustomLink extends Link {
    static create(value: string) {
        const isTargetBlank = isTargetBlankRegex.test(value);
        const node = Link.create(value);
        value = Link.sanitize(value).replace('?isBlank=true', '');

        node.setAttribute('href', value);
        node.setAttribute('target', '_blank');
        if (!isTargetBlank) {
            node.removeAttribute('target');
        }

        return node;
    }

    format(name: any, value: any) {
        const isTargetBlank = isTargetBlankRegex.test(value);
        if (value) {
            value = Link.sanitize(value).replace('?isBlank=true', '');
            super.format(name, value);
            if (isTargetBlank) {
                this.domNode.setAttribute('target', '_blank');
            } else {
                this.domNode.removeAttribute('target');
            }
        } else {
            super.format(name, value);
        }
    }
}

Quill.register(CustomLink);

type FormEditorProps = {
    value: string;
    setValue: (s: string) => void;
    readOnly: boolean;
};
const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column' as const,
};

const CustomToolbar = ({ id }: { id: string }) => (
    <div id={id}>
        <select className="ql-size">
            <option value="extra-small">Size 1</option>
            <option value="small">Size 2</option>
            <option value="medium" selected>
                Size 3
            </option>
            <option value="large">Size 4</option>
        </select>
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
        <button className="ql-clean" />
        <button className="ql-link" />
    </div>
);

const FormEditor = ({ value, setValue, readOnly }: FormEditorProps) => {
    const [quill, setQuill] = useState<any>();
    const [id] = useState(`unique-quill-toolbar-${Math.floor(Math.random() * 100000000000000)}`);
    const [open, setOpen] = React.useState(false);
    const [linkUrl, setLinkUrl] = useState('');
    const [openNewTab, setOpenNewTab] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        if (quill) {
            quill.focus();
            quill.format('link', linkUrl + (openNewTab ? '?isBlank=true' : ''));
            setLinkUrl('');
            setOpenNewTab(false);
        }
    };
    const linkTest = useCallback(function (this: any, value: any) {
        handleOpen();
        setQuill(this.quill);
    }, []);

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: `#${id}`,
                handlers: {
                    link: linkTest,
                },
            },
        };
    }, []);

    const formats = useMemo(
        () => [
            'header',
            'font',
            'size',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'indent',
            'link',
            'image',
            'color',
        ],
        [],
    );

    return (
        <>
            <CustomToolbar id={id} />
            <ReactQuill
                modules={modules}
                formats={formats}
                theme="snow"
                value={value}
                onChange={setValue}
                readOnly={readOnly}
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <TextField
                        id="link-url"
                        type="text"
                        label="Link URL"
                        name="link-url"
                        onChange={(e) => {
                            setLinkUrl(e.target.value);
                        }}
                        value={linkUrl}
                        variant={'outlined'}
                    />
                    <FormControlLabel
                        control={<Checkbox value={openNewTab} onChange={() => setOpenNewTab(!openNewTab)} />}
                        label="Ouvrir dans un nouvel onglet"
                    />
                    <Button onClick={handleClose}>Valider</Button>
                </Box>
            </Modal>
        </>
    );
};

export default FormEditor;

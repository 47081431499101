import { Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';

class BooleanConverter {
    static convert = (value: number): boolean => {
        return !!value;
    };
    static invert = (boolean: boolean): number => {
        return boolean ? 1 : 0;
    };
    static display = (input: boolean): JSX.Element => {
        const theme = useTheme();
        return input ? (
            <Chip sx={{ backgroundColor: theme.palette.success.light, fontWeight: 'bold' }} label="oui" />
        ) : (
            <Chip sx={{ backgroundColor: theme.palette.error.light, fontWeight: 'bold' }} label="non" />
        );
    };
}

export default BooleanConverter;

import { useContext, useEffect, useState, MouseEvent, useMemo } from 'react';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Fab,
    IconButton,
    Typography,
    Box,
    Checkbox,
    Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled, useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { AxiosError } from 'axios';
import { IconTrash } from '@tabler/icons';

// Types
import { ConfigListType, MassActionType, TableListElementType } from '../types/lists/ConfigListType';
import { ListFilterType } from '../types/lists/ListFilterType';
import { ResourceType } from '../types/ResourceType';
import { ActionType, CustomActionType } from '../types/ActionsType';

// Project import
import { ApiContext, ApiLoading } from '../AppBoBuilder';
import { flashMessage, flashType } from './Flash';
import TableListFilters from './TableListFilters';
import CustomLoader from './CustomLoader';
import Paginator from './Paginator';
import ApiService from '../services/ApiService';
import WhiteBox from '../components/styled/WhiteBox';
import DeleteModal from './DeleteModal';
import { getObjectProperty } from 'm6BoBuilder/utils/getObjectProperty';
import { FilterType } from 'm6BoBuilder/types/FilterType';

type TableListProps = {
    resource: ResourceType;
    config: ConfigListType;
    actions?: ActionType[];
    listPath?: string | null;
    showAddCta?: boolean;
    navigateOnClick?: boolean;
    forceRefresh?: boolean;
};

const initialState: InitialStateType = {
    list: [],
    error: '',
    filters: { page: 1 },
    currentPage: 1,
};

type InitialStateType = {
    list: { ['@id']: string; id: string }[];
    error: string;
    currentPage: number;
    filters: ListFilterType;
};

type Order = 'asc' | 'desc';

type SelectableTableRowProps = {
    navigate?: boolean;
    onSelect: (checked: boolean, element: { id: string }) => void;
    element: { ['@id']: string; id: string };
    index: number;
    resource: ResourceType;
    actions?: ActionType[];
    theme: any;
    config: ConfigListType;
    checked: boolean;
    showDeleteModal: (id: string) => (event: MouseEvent<HTMLButtonElement>) => void;
};

const SelectableTableRow = ({
    navigate,
    onSelect,
    element,
    index,
    resource,
    theme,
    config,
    showDeleteModal,
    actions,
    checked,
}: SelectableTableRowProps) => {
    return (
        <TableRow
            component={navigate !== false ? Link : Box}
            key={element['@id']}
            to={`/${resource.resourcePath}/${element.id}`}
            sx={[
                index % 2
                    ? { backgroundColor: theme.palette.background.default }
                    : { backgroundColor: theme.palette.action.hover },
                navigate !== false && {
                    cursor: 'pointer',
                    textDecoration: 'none',
                    '&:hover': {
                        backgroundColor:
                            theme.palette.mode === 'dark' ? theme.palette.primary[800] : theme.palette.secondary.light,
                    },
                },
                checked && {
                    backgroundColor:
                        theme.palette.mode === 'dark' ? theme.palette.primary[800] : theme.palette.secondary.light,
                },
            ]}
        >
            {config?.selectable && (
                <TableCell key={`sort-${element.id}`} align="left">
                    <Checkbox
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onSelect(!checked, element);
                        }}
                        checked={checked}
                    />
                </TableCell>
            )}
            {config.listProperties.map((configLine: TableListElementType) => (
                <TableCell key={configLine.property} align="left" sx={configLine.styles}>
                    {getObjectProperty(element, configLine.property, configLine.transform ?? null)}
                </TableCell>
            ))}
            {resource.actions && (
                <TableCell>
                    {resource.actions.delete && (
                        <IconButton onClick={showDeleteModal(element['id'])} color="error">
                            <IconTrash />
                        </IconButton>
                    )}
                    {resource.actions.customs && (
                        <>
                            {resource.actions.customs.map((customAction: CustomActionType, index) => {
                                const Action = customAction.component;
                                return (
                                    <Action
                                        key={`action_${index}`}
                                        config={customAction.config || {}}
                                        id={element.id}
                                    />
                                );
                            })}
                        </>
                    )}
                    {actions && (
                        <>
                            {actions.map((Action: ActionType, index) => {
                                return <Action key={index} config={{}} id={element.id} />;
                            })}
                        </>
                    )}
                </TableCell>
            )}
        </TableRow>
    );
};

const AddCta: any = styled(Fab)(() => ({
    position: 'fixed',
    bottom: 30,
    right: 30,
    zIndex: 500,
}));

const MassActionsContainer: any = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '25px',
    minHeight: '37px',
    '& > p:first-of-type': {
        marginRight: '30px',
    },
}));

const TableList = ({
    resource,
    config,
    actions,
    listPath = null,
    showAddCta,
    navigateOnClick,
    forceRefresh,
}: TableListProps): JSX.Element => {
    if (config.defaultOrder !== undefined) {
        initialState.filters[`order[${config.defaultOrder.property}]`] = config.defaultOrder.order;
    } else {
        initialState.filters['order[id]'] = 'desc';
    }
    initialState.filters['itemsPerPage'] = config.itemsPerPage ?? 20;

    // Add default filter value set in config
    config.filters?.map((filter: FilterType) => {
        if (filter.options?.default !== undefined) {
            initialState.filters[filter.property] = filter.options.default;
        }
    });

    const apiService: ApiService = useContext(ApiContext);
    const isApiLoading = useContext(ApiLoading);
    const [selected, setSelected] = useState<string[]>([]);
    const [maxPage, setMaxPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState(initialState.filters);
    const [list, setList] = useState(initialState.list);
    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState(config.defaultOrder?.property ?? 'id');
    const [isDeleteModalVisible, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState('');

    const navigate = selected.length > 0 ? false : navigateOnClick;
    const theme = useTheme();

    const onSelect = (checked: boolean, element: { id: string }) => {
        if (checked) {
            setSelected([...selected, element.id]);
        } else {
            setSelected(selected.filter((id) => id !== element.id));
        }
    };

    const getList = (): void => {
        apiService
            .getAll(listPath ?? config.resourcePath ?? resource.resourcePath, {
                ...filters,
                page: currentPage,
            })
            .then((data) => {
                if (data !== null) {
                    setList(data.data['hydra:member']);
                    setMaxPage(Math.ceil(data.data['hydra:totalItems'] / filters.itemsPerPage));
                }
            })
            .catch((error: AxiosError) => {
                flashMessage(error.response?.data['hydra:description'] ?? error.message, flashType.ERROR);
            });
    };

    const updateFilters = (filterFields: any) => {
        setFilters({ ...filterFields });
    };

    const updateCurrentPage = (selectedPage: number) => {
        setCurrentPage(selectedPage);
    };

    useEffect(() => {
        getList();
    }, [currentPage, filters]);

    useEffect(() => {
        if (forceRefresh) {
            getList();
        }
    }, [forceRefresh]);

    const handleSort = (property: any) => (_event: any) => {
        delete filters[`order[${orderBy}]`];
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        updateFilters({
            [`order[${property}]`]: isAsc ? 'desc' : 'asc',
        });
    };

    const showDeleteModal = (id: string) => (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event?.stopPropagation();
        setDeleteId(id);
        setDeleteModal(true);
    };

    const handleDelete = async () => {
        await apiService.remove(resource.resourcePath, deleteId);
        getList();
        setDeleteModal(false);
        flashMessage(`${resource.configForm.label} #${deleteId} a bien été supprimée`);
    };

    const applyAction = async (action: (entity: any, apiService: ApiService) => Promise<void>) => {
        const promiseList: Promise<any>[] = [];
        selected.forEach(async (id) => {
            promiseList.push(
                action(
                    list.find((element) => element.id === id),
                    apiService,
                ),
            );
        });
        await Promise.all(promiseList);
        flashMessage(`${selected.length} ${resource.configForm.label} ont bien été modifiées`);
        setSelected([]);
    };

    const selectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSelected(list.map((element) => element.id));
        } else {
            setSelected([]);
        }
    };

    const table = useMemo(
        () =>
            list?.map((element, index) => (
                <SelectableTableRow
                    checked={selected.includes(element.id)}
                    navigate={navigate}
                    onSelect={onSelect}
                    element={element}
                    index={index}
                    resource={resource}
                    theme={theme}
                    config={config}
                    showDeleteModal={showDeleteModal}
                    actions={actions}
                    key={element.id}
                />
            )),
        [list, selected],
    );

    return (
        <>
            {config.allowAdd && showAddCta !== false && (
                <AddCta color="secondary" component={Link} to={`/${resource.resourcePath}/new`}>
                    <AddIcon fontSize="medium" />
                </AddCta>
            )}
            <WhiteBox>
                <Grid container spacing={2}>
                    {config.filters && (
                        <Grid item xs={12}>
                            <TableListFilters
                                filters={config.filters}
                                defaultValues={initialState.filters}
                                updateFilters={(f) => {
                                    setCurrentPage(1);
                                    updateFilters(f);
                                }}
                                values={filters}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        {isApiLoading || !list ? (
                            <Grid container justifyContent="center" alignItems="center">
                                <CustomLoader />
                            </Grid>
                        ) : list.length ? (
                            <>
                                {config.selectable && (
                                    <MassActionsContainer>
                                        {selected.length > 0 && (
                                            <>
                                                <Typography variant="h4" component={'p'}>
                                                    {`${selected.length} Élément${
                                                        selected.length > 1 ? 's' : ''
                                                    } sélectionné${selected.length > 1 ? 's' : ''}`}
                                                </Typography>
                                                {config.massActions?.map((action: MassActionType, index) => {
                                                    return (
                                                        <Button
                                                            key={index}
                                                            variant="contained"
                                                            onClick={() => applyAction(action.action)}
                                                        >
                                                            {action.label}
                                                        </Button>
                                                    );
                                                })}
                                            </>
                                        )}
                                    </MassActionsContainer>
                                )}

                                <TableContainer>
                                    <Table stickyHeader className={resource.resourcePath}>
                                        <TableHead>
                                            <TableRow>
                                                {config?.selectable && (
                                                    <TableCell key={'sort-header'} style={{ fontWeight: 'bold' }}>
                                                        <Checkbox
                                                            checked={selected.length > 0}
                                                            indeterminate={
                                                                selected.length > 0 && selected.length !== list.length
                                                            }
                                                            onChange={selectAll}
                                                        />
                                                    </TableCell>
                                                )}
                                                {config.listProperties.map((configLine: TableListElementType) => (
                                                    <TableCell key={configLine.property} style={{ fontWeight: 'bold' }}>
                                                        {configLine.sortable ? (
                                                            <TableSortLabel
                                                                active={orderBy === configLine.property}
                                                                direction={
                                                                    orderBy === configLine.property ? order : 'asc'
                                                                }
                                                                onClick={handleSort(configLine.property)}
                                                            >
                                                                {configLine.label}
                                                            </TableSortLabel>
                                                        ) : (
                                                            configLine.label
                                                        )}
                                                    </TableCell>
                                                ))}
                                                {resource.actions && (
                                                    <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>{table}</TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        ) : (
                            <Typography sx={{ p: 2 }}>Aucun élément</Typography>
                        )}
                    </Grid>
                </Grid>
            </WhiteBox>
            {!isApiLoading && (
                <Paginator maxPage={maxPage} currentPage={currentPage} updateCurrentPage={updateCurrentPage} />
            )}

            <DeleteModal
                modalOpen={isDeleteModalVisible}
                closeModal={() => setDeleteModal(false)}
                handleDelete={handleDelete}
            />
        </>
    );
};
export default TableList;

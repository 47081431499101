import 'App.css';

import { AppBoBuilder, ConfigType } from 'm6BoBuilder';
import { default as conf } from 'config/config';
import navigation from './config/navigation';
import screens from './config/screens';
import generateCustomRouteList from './config/CustomRoutes';

const config: ConfigType = {
    allowedRoles: conf.allowedRoles,
    navigation: navigation,
    screens: screens,
    getCustomRouteList: generateCustomRouteList,
};

const App = (): JSX.Element => {
    return <AppBoBuilder config={config} />;
};

export default App;

import * as yup from 'yup';
import { InputType, ResourceType, ImageConverter } from 'm6BoBuilder';

const CoachProperties = {
    id: 'id',
    firstname: 'firstname',
    lastname: 'lastname',
    signature: 'signature',
    photo: 'photo',
    videos: 'videos',
    hasExercises: 'hasExercises',
};

const Coach: ResourceType = {
    resourcePath: 'coaches',
    placeHolder: {
        [CoachProperties.id]: '',
        [CoachProperties.firstname]: '',
        [CoachProperties.lastname]: '',
        [CoachProperties.signature]: '',
        [CoachProperties.photo]: null,
        [CoachProperties.hasExercises]: false,
        // [CoachProperties.videos]: [],
    },
    validationSchema: yup.object({
        [CoachProperties.firstname]: yup.string().required('Ce champ est obligatoire'),
        [CoachProperties.lastname]: yup.string().required('Ce champ est obligatoire'),
    }),
    configForm: {
        label: 'Coach',
        listInputs: [
            {
                label: 'Nom',
                property: CoachProperties.firstname,
            },
            {
                label: 'Prénom',
                property: CoachProperties.lastname,
            },
            {
                label: 'Signature',
                property: CoachProperties.signature,
            },
            {
                label: 'Photo',
                property: CoachProperties.photo,
                type: InputType.image,
                options: {
                    resource: 'media_objects',
                },
            },
            {
                label: 'Possède des exercices à la carte',
                property: CoachProperties.hasExercises,
                type: InputType.boolean,
            },
        ],
    },
    configList: {
        label: 'Coaches',
        listProperties: [
            {
                label: '#',
                property: CoachProperties.id,
                sortable: true,
                styles: {
                    width: '7%',
                },
            },
            {
                label: 'Photo',
                property: CoachProperties.photo,
                transform: ImageConverter.display,
                styles: {
                    width: '30%',
                },
            },
            {
                label: 'Nom',
                property: CoachProperties.firstname,
                sortable: true,
            },
            {
                label: 'Prénom',
                property: CoachProperties.lastname,
                sortable: true,
            },
            {
                label: 'Signature',
                property: CoachProperties.signature,
                sortable: true,
            },
        ],
        allowAdd: true,
        filters: [],
        defaultOrder: {
            property: 'id',
            order: 'desc',
        },
    },
    actions: {
        delete: true,
    },
};

export default Coach;
export { CoachProperties };

import { useEffect, useState } from 'react';
import Bus from '../services/Bus';
import { Alert, AlertColor, Slide } from '@mui/material';
import { styled } from '@mui/material/styles';

interface FlashWindows extends Window {
    flash: (message: string, type: string) => void;
}

type AlertType = {
    display: boolean;
    message: string;
    severity: AlertColor;
};

const initialState: { alert: AlertType } = {
    alert: {
        display: false,
        message: '',
        severity: 'success',
    },
};

const flashType = {
    SUCCESS: 'success',
    ERROR: 'error',
};

const AlertContainer = styled('div')(() => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 5000,
    padding: '10px 20%',
}));

const eventName = 'flash';

declare let window: FlashWindows;
window.flash = (message: string, type = flashType.SUCCESS) => Bus.emit(eventName, { message, type });

const flashMessage = (message: string, type = flashType.SUCCESS): void => {
    window.flash(message, type);
};

const Flash = (): JSX.Element => {
    const [visibility, setVisibility] = useState(initialState.alert.display);
    const [message, setMessage] = useState(initialState.alert.message);
    const [type, setType] = useState(initialState.alert.severity);

    useEffect(() => {
        Bus.addListener(eventName, ({ message, type }) => {
            setMessage(message);
            setType(type);
            setVisibility(true);

            const timer = type === flashType.ERROR ? 10000 : 4000;

            setTimeout(() => {
                setVisibility(false);
            }, timer);
        });
    }, []);

    return (
        <Slide in={visibility}>
            <AlertContainer>
                <Alert variant="filled" severity={type}>
                    {message}
                </Alert>
            </AlertContainer>
        </Slide>
    );
};

export { flashMessage, flashType };

export default Flash;

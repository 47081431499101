export const setObjectProperty = (obj: any, accessor: string, value: any) => {
    const props = accessor.split('.');
    let schema = obj;
    const len = props.length;
    for (let i = 0; i < len - 1; i++) {
        const elem = props[i];
        if (!schema[elem]) schema[elem] = {};
        schema = schema[elem];
    }

    schema[props[len - 1]] = value;
};

import { InputType, ResourceType, TagConverter } from 'm6BoBuilder';
import * as yup from 'yup';
import BodyPart from './BodyPart';
import Level, { LevelProperties } from './Level';
import Coach, { CoachProperties } from './Coach';
import Sport, { SportProperties } from './Sport';
import Equipment from './Equipment';

const VideoProperties = {
    id: 'id',
    title: 'title',
    uuid: 'uuid',
    coach: 'coach',
    bodyParts: 'bodyParts',
    level: 'level',
    sport: 'sport',
    videoCategory: 'videoCategory',
    equipments: 'equipments',
};

const Video: ResourceType = {
    resourcePath: 'videos',
    placeHolder: {
        [VideoProperties.id]: '',
        [VideoProperties.title]: '',
        [VideoProperties.uuid]: '',
        [VideoProperties.coach]: undefined,
        [VideoProperties.bodyParts]: [],
        [VideoProperties.level]: undefined,
        [VideoProperties.sport]: undefined,
        [VideoProperties.videoCategory]: undefined,
        [VideoProperties.equipments]: [],
    },
    validationSchema: yup.object({
        title: yup.string().required('Ce champ est obligatoire'),
    }),
    configForm: {
        label: 'Video',
        listInputs: [
            {
                label: 'Nom',
                property: VideoProperties.title,
            },
            {
                label: 'ID Viméo de la vidéo',
                property: VideoProperties.uuid,
            },
            {
                label: 'Coach',
                property: VideoProperties.coach,
                type: InputType.entity,
                options: {
                    resource: Coach.resourcePath,
                    label: CoachProperties.signature,
                },
            },
            {
                label: 'Type de vidéo',
                property: VideoProperties.videoCategory,
                type: InputType.entity,
                options: {
                    resource: 'video_categories',
                },
            },
            {
                label: Sport.configForm.label,
                property: VideoProperties.sport,
                type: InputType.entity,
                options: {
                    resource: Sport.resourcePath,
                },
            },
            {
                label: Equipment.configForm.label,
                property: VideoProperties.equipments,
                type: InputType.entity,
                options: {
                    resource: Equipment.resourcePath,
                    multiple: true,
                },
            },
            {
                label: BodyPart.configList.label,
                property: VideoProperties.bodyParts,
                type: InputType.entity,
                options: {
                    resource: BodyPart.resourcePath,
                    multiple: true,
                },
            },
            {
                label: Level.configForm.label,
                property: VideoProperties.level,
                type: InputType.entity,
                options: {
                    resource: Level.resourcePath,
                },
            },
        ],
    },
    configList: {
        label: 'Videos',
        listProperties: [
            {
                label: '#',
                property: VideoProperties.id,
                sortable: true,
                styles: {
                    width: '7%',
                },
            },
            {
                label: 'Nom',
                property: VideoProperties.title,
                sortable: true,
            },
            {
                label: 'Coach',
                property: `${VideoProperties.coach}.${CoachProperties.signature}`,
            },
            {
                label: 'Type',
                property: `${VideoProperties.videoCategory}.title`,
            },
            {
                label: 'ID Viméo',
                property: VideoProperties.uuid,
            },
            {
                label: 'Niveau',
                property: `${VideoProperties.level}.${LevelProperties.title}`,
            },
            {
                label: Equipment.configList.label,
                property: VideoProperties.equipments,
                transform: (elements: []) => elements.map((elt) => TagConverter.display(elt, 'title')),
            },
            {
                label: BodyPart.configForm.label,
                property: VideoProperties.bodyParts,
                transform: (elements: []) => elements.map((elt) => TagConverter.display(elt, 'title')),
            },
            {
                label: 'Sport',
                property: `${VideoProperties.sport}.${SportProperties.title}`,
            },
        ],
        allowAdd: true,
        filters: [
            {
                label: 'Titre',
                property: VideoProperties.title,
            },
        ],
        defaultOrder: {
            property: 'id',
            order: 'desc',
        },
    },
    actions: {
        delete: true,
    },
};

export default Video;
export { VideoProperties };

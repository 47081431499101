import * as yup from 'yup';
import { DateConverter, ResourceType, InputType, BooleanConverter } from 'm6BoBuilder';

const PromotionalCommunicationProperties = {
    id: 'id',
    title: 'title',
    publicationStartDate: 'publicationStartDate',
    publicationEndDate: 'publicationEndDate',
    published: 'published',
};

const PromotionalCommunication: ResourceType = {
    resourcePath: 'bo-promotional-communications',
    placeHolder: {
        [PromotionalCommunicationProperties.id]: 0,
        [PromotionalCommunicationProperties.title]: '',
        [PromotionalCommunicationProperties.publicationStartDate]: new Date(),
        [PromotionalCommunicationProperties.publicationEndDate]: undefined,
        [PromotionalCommunicationProperties.published]: false,
    },
    validationSchema: yup.object({
        title: yup.string().required('Ce champ est obligatoire'),
        publicationStartDate: yup.date().required('Ce champ est obligatoire'),
        publicationEndDate: yup.date().required('Ce champ est obligatoire'),
        published: yup.string().required('Ce champ est obligatoire'),
    }),

    configForm: {
        label: 'Bandeau promotionnel',
        listInputs: [
            {
                label: 'Contenu',
                property: PromotionalCommunicationProperties.title,
                options: {
                    textArea: true,
                },
            },
            {
                label: 'Date de publication',
                property: PromotionalCommunicationProperties.publicationStartDate,
                type: InputType.date,
            },
            {
                label: 'Date de fin de publication',
                property: PromotionalCommunicationProperties.publicationEndDate,
                type: InputType.date,
            },
            {
                label: 'Est publié ?',
                property: PromotionalCommunicationProperties.published,
                type: InputType.boolean,
            },
        ],
    },

    configList: {
        label: 'Bandeau promotionnel',
        listProperties: [
            {
                label: 'ID',
                property: PromotionalCommunicationProperties.id,
                sortable: true,
            },
            {
                label: 'Contenu',
                property: PromotionalCommunicationProperties.title,
            },
            {
                label: 'Date de publication',
                property: PromotionalCommunicationProperties.publicationStartDate,
                transform: DateConverter.display,
            },
            {
                label: 'Date de fin',
                property: PromotionalCommunicationProperties.publicationEndDate,
                transform: DateConverter.display,
            },
            {
                label: 'Statut',
                property: PromotionalCommunicationProperties.published,
                transform: BooleanConverter.display,
            },
        ],
        allowAdd: true,
    },
};

export default PromotionalCommunication;
export { PromotionalCommunicationProperties };

import { ChangeEvent, useEffect, useState, useRef } from 'react';
import { InputType } from '../../types/forms/ConfigFormType';
import { FormElementPropsType } from '../../types/forms/FormElementPropsType';
import { Typography, TextField } from '@mui/material';
import { getObjectProperty } from 'm6BoBuilder/utils/getObjectProperty';
import FormEditor from './FormEditor';
import { setObjectProperty } from '../../utils/setObjectProperty';

const FormInput = ({ entity, updateEntity, error, configLine, endAdornment }: FormElementPropsType): JSX.Element => {
    const [value, setValue] = useState(
        getObjectProperty(
            entity,
            configLine.property,
            undefined,
            configLine.type === InputType.password ? '' : undefined,
        ),
    );

    useEffect(() => {
        setValue(
            getObjectProperty(
                entity,
                configLine.property,
                undefined,
                configLine.type === InputType.password ? '' : undefined,
            ),
        );
    }, [entity]);

    const readOnly = typeof configLine.options?.readOnly === 'undefined' ? false : configLine.options?.readOnly;

    const useDidMountEffect = (func: any, deps: any) => {
        const didMount = useRef(false);

        useEffect(() => {
            if (didMount.current) func();
            else didMount.current = true;
        }, deps);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value: number | string = event.target.value;
        if (
            typeof configLine.type !== 'undefined' &&
            configLine.type !== InputType.text &&
            configLine.type !== InputType.password &&
            value === ''
        ) {
            value = '0';
        }

        setValue(value);
    };

    useDidMountEffect(() => {
        if (typeof value === 'string') {
            setObjectProperty(entity, configLine.property, value.replace(/<p><br><\/p>/g, '<br>'));
        }

        switch (configLine.type) {
            case InputType.integer:
                setObjectProperty(entity, configLine.property, parseInt(value));
                break;
            case InputType.password:
                entity[configLine.property] = value === '' ? null : value;
                break;
            case InputType.float:
                setObjectProperty(entity, configLine.property, parseFloat(value));
                break;
        }
        updateEntity(entity, configLine.property);
    }, [value]);

    if (configLine.options?.textArea) {
        return (
            <>
                <Typography component="h2" variant="h6" mb={1}>
                    {configLine.label}
                </Typography>
                <FormEditor value={value} setValue={setValue} readOnly={readOnly} />
            </>
        );
    } else {
        return (
            <TextField
                id={configLine.property}
                type={
                    configLine.type === InputType.integer || configLine.type === InputType.float
                        ? 'number'
                        : configLine.type ?? InputType.text
                }
                error={error}
                fullWidth
                label={configLine.label}
                name={configLine.property}
                onChange={handleChange}
                value={value}
                variant={'outlined'}
                inputProps={{
                    readOnly: readOnly,
                }}
                InputProps={{
                    endAdornment,
                }}
            />
        );
    }
};
export default FormInput;

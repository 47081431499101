import { FormEntity, FormElementType, SubFormType } from 'm6BoBuilder';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Video from 'config/resources/Video';
import { ProgramVideoPlaceHolder, ProgramVideoProperties } from 'config/resources/ProgramVideo';

const SubformProgramVideo = ({ position, entity, updateEntity, configLine }: SubFormType): JSX.Element => {
    const [subEntity, setSubEntity] = useState(entity[configLine.property][position] ?? ProgramVideoPlaceHolder);
    const configProgramVideo: FormElementType = {
        label: 'Vidéo',
        property: ProgramVideoProperties.video,
        options: {
            details: {
                path: 'coach.signature',
                prelabel: 'coach ',
            },
            resource: Video.resourcePath,
        },
    };

    useEffect(() => {
        setSubEntity(subEntity);
    }, [entity]);

    const updateSubEntity = (updatedSubEntity: Record<string, any>) => {
        entity[configLine.property][position] = updatedSubEntity;

        setSubEntity(updatedSubEntity);
        updateEntity(entity, configLine.property);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={10}>
                <FormEntity entity={subEntity} updateEntity={updateSubEntity} configLine={configProgramVideo} />
            </Grid>
        </Grid>
    );
};

export default SubformProgramVideo;

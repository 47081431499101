import { Grid } from '@mui/material';

import { gridSpacing } from '../berry/store/constant';

function LandingScreen(): JSX.Element {
    return (
        <>
            <Grid container spacing={gridSpacing}></Grid>
        </>
    );
}

export default LandingScreen;

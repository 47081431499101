const ProgramVideoProperties = {
    id: 'id',
    video: 'video',
    position: 'position',
};
const ProgramVideoPlaceHolder = {
    id: '',
    video: null,
};
export { ProgramVideoProperties, ProgramVideoPlaceHolder };

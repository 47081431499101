const roles = {
    ROLE_ADMIN: 'ROLE_ADMIN',
    ROLE_COACH_CB: 'ROLE_COACH_CB',
};

const config = {
    allowedRoles: [roles.ROLE_ADMIN, roles.ROLE_COACH_CB],
};

export default config;

export { roles };
